import { type AdmittedStudentInfo } from 'AppBranding';
import { type AdmittedStudentsZoomStatus } from './StudentDashboard.types';

export default function admittedStudentsZoomStatus(
    admittedStudentInfo: AdmittedStudentInfo,
): AdmittedStudentsZoomStatus {
    const { registrationUrl, recordingUrl, webinarDate } = admittedStudentInfo;

    if (!registrationUrl || !webinarDate) {
        return 'hidden';
    }

    if (registrationUrl && webinarDate > new Date()) {
        return 'saveTheDate';
    }

    const fourWeeks = 1000 * 60 * 60 * 24 * 28;
    const fourWeeksAgo = new Date(Date.now() - fourWeeks);
    if (recordingUrl && webinarDate > fourWeeksAgo) {
        return 'watchRecording';
    }

    return 'hidden';
}

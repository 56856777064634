
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.admissions_modals = window.Smartly.locales.modules.en.back_royal.admissions_modals || {};
window.Smartly.locales.modules.en.back_royal.admissions_modals.welcome_to_dashboard = window.Smartly.locales.modules.en.back_royal.admissions_modals.welcome_to_dashboard || {};
window.Smartly.locales.modules.en.back_royal.admissions_modals.welcome_to_dashboard = {...window.Smartly.locales.modules.en.back_royal.admissions_modals.welcome_to_dashboard, ...{
    "welcome": "Welcome to {{brandName}}!",
    "try_any_free_lessons": "Ready to learn?<br/><a ng-click=\"onClickOpenCourses()\">Try our open courses</a>.",
    "complete_your_application": "Ready to apply?<br/><a ng-click=\"completeApplication()\">Complete free application</a>.",
    "admissions_question": "Admissions question?<br/><a ng-click=\"onClickAdmissionsQuestion()\">Get answers here</a>.",
    "friends_or_colleagues": "Friends or colleagues?<br/><a ng-click=\"onClickAlumniNetwork()\">Search our alumni network</a>.",
    "need_more_info": "Need more info?<br/><a ng-click=\"onClickNeedMoreInfo()\">View {{brandName}} website</a>.",
    "try_our_lessons": "Try our lessons<br /><a ng-click=\"onClickOpenCourses()\">Sample open courses</a>"
}
}
    
export default angular.module('FrontRoyal.AMap', []).constant('AMAP_CONFIG', {
    version: '1.4.15',
    amap_ui: {
        version: '1.1',
        plugins: ['overlay/SimpleMarker'],
    },
    plugins: ['AMap.Autocomplete', 'AMap.DistrictSearch'],
    // AMap POI type: Provincial Place Name|Prefecture-level Place Name
    // TODO: AMAP has a bug in auto-complete search, Municipal Place Name(北京市、上海市、天津市、重庆市)
    // don't show up in search result. For now, we have to allow Provincial Place Name to include the 4 cities.
    // After AMAP fixes the bug, set city type to '190103|190104': Municipal Place Name|Prefecture-level Place Name
    // see: https://trello.com/c/sXPKxmBq/100-bugs-bugs-reported-from-michelles-qa-2022-02-13
    poi_type_city: '190102|190104',
    toolbar: {
        plugins: ['AMap.ToolBar'],
    },
});

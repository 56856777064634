import { useCallback, useMemo } from 'react';
import { useCurrentUser } from 'FrontRoyalAngular';
import { useTranslation } from 'react-i18next';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { useUserIdVerificationViewModel } from 'FrontRoyalAngular/useUserIdVerificationViewModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/pro-regular-svg-icons/faIdCard';

export function IdVerificationBox() {
    return <IdVerificationBoxPropsWrapper />;
}

function IdVerificationBoxPropsWrapper() {
    const currentUser = useCurrentUser();
    const { xsOrSm } = useIsMobileMixin();
    const UserIdVerificationViewModel = useUserIdVerificationViewModel();

    const userIdVerificationViewModel = useMemo(() => new UserIdVerificationViewModel(), [UserIdVerificationViewModel]);

    const handleVerifyClick = useCallback(() => {
        userIdVerificationViewModel.launchVerificationModal();
    }, [userIdVerificationViewModel]);

    if (!currentUser) return null;

    return <IdVerificationBoxPresentation xsOrSm={xsOrSm} onVerifyClick={handleVerifyClick} />;
}

export function IdVerificationBoxPresentation({
    xsOrSm,
    onVerifyClick,
}: {
    xsOrSm: boolean;
    onVerifyClick: () => void;
}) {
    const { t } = useTranslation('back_royal');

    if (xsOrSm) {
        // MOBILE view
        return (
            <div
                data-testid="content-container"
                className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-p-5"
            >
                <p className="tw-mb-[30px] tw-text-md tw-font-normal tw-text-white">
                    {t('lessons.stream.student_dashboard_identity_verification.in_order_to_continue')}
                </p>
                <button
                    className="tw-mb-[10px] tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-[50px] tw-border tw-border-solid tw-border-white tw-bg-transparent tw-px-[30px] tw-py-[10px] tw-text-base tw-font-medium tw-text-white tw-shadow-none"
                    type="button"
                    onClick={onVerifyClick}
                    data-testid="verify_id"
                >
                    {t('lessons.stream.student_dashboard_identity_verification.verify_identity')}
                </button>
            </div>
        );
    }

    return (
        // DESKTOP view
        <div className="tw-bg-[linear-gradient(168deg,_#a52e3b_0%,_#600b14_96%)] tw-px-5 tw-pb-[30px] tw-pt-[20px]">
            <div data-testid="content-container" className="tw-flex tw-flex-col tw-items-center tw-justify-start">
                <h2 className="tw-mb-[20px] tw-flex tw-flex-col tw-items-center tw-justify-start">
                    <span className="tw-mb-[5px] tw-block tw-text-xs tw-font-normal tw-uppercase tw-text-white">
                        {t('lessons.stream.student_dashboard_identity_verification.identity')}
                    </span>
                    <span className="tw-mb-[10px] tw-block tw-text-[28px] tw-font-semibold tw-uppercase tw-text-white">
                        {t('lessons.stream.student_dashboard_identity_verification.verification')}
                    </span>
                    <span className="tw-flex tw-h-[40px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-full tw-bg-yellow tw-text-lg tw-font-normal tw-text-red-dark">
                        <FontAwesomeIcon icon={faIdCard} />
                    </span>
                </h2>
                <p className="tw-mb-[18px] tw-px-[22px] tw-text-center tw-text-md tw-font-normal tw-leading-[26px] tw-text-white">
                    {t('lessons.stream.student_dashboard_identity_verification.in_order_to_continue')}
                </p>
                <button
                    className="tw-m-0 tw-flex tw-h-auto tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded tw-border-0 tw-bg-transparent tw-bg-yellow tw-px-4 tw-py-[10px] tw-text-base tw-font-normal tw-normal-case tw-leading-[1.33] tw-text-red-dark tw-shadow-none tw-transition-[all_.3s_ease] hover:tw-bg-[#ffcb33]"
                    data-testid="verify_id"
                    type="button"
                    onClick={onVerifyClick}
                >
                    {t('lessons.stream.student_dashboard_identity_verification.verify_identity')}
                </button>
            </div>
        </div>
    );
}

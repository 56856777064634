import { AdmissionsProcessModalsViewModel } from 'AdmissionsProcessModals';

const angularModule = angular.module('AdmissionsProcessModals', []);

angularModule.factory('admissionsProcessModalsViewModel', [
    '$injector',
    $injector => new AdmissionsProcessModalsViewModel($injector),
]);

angularModule.run([
    '$injector',
    $injector => {
        // initialize the view model so we start watching for route changes
        $injector.get('admissionsProcessModalsViewModel');
    },
]);

export default angularModule;

import { type AnyObject } from '@Types';
import { type TodoItem } from 'AdmissionsGuidance/StudentDashboard.types';

export function enrollmentTodoVisible(item: TodoItem, showCompletedTodos: boolean) {
    if (!item.isVisible()) return false;
    if (item.hasBeenCompleted() && !showCompletedTodos) return false;
    return true;
}

export function enrollmentSubTodoVisible(subTodo: AnyObject, todo: TodoItem, showCompletedTodos: boolean) {
    return (
        !!todo.subTodosConfig?.isVisible?.() && (!todo.subTodosConfig!.hasBeenCompleted(subTodo) || showCompletedTodos)
    );
}

export function hasIncompleteItem(items: TodoItem[]) {
    return items.some(item => item.isVisible() && !item.hasBeenCompleted());
}

export function hasCompleteItem(items: TodoItem[]) {
    return items.some(item => item.isVisible() && item.hasBeenCompleted());
}

export function enrollmentTodoGroupVisible(items: TodoItem[], showCompletedTodos: boolean) {
    return hasIncompleteItem(items) || (hasCompleteItem(items) && showCompletedTodos);
}

import { aMapLoaderWrapper, logAMapLoadError } from './aMapHelper';

// 1. If the user enters a couple characters and presses enter without
// selecting a place, this gets triggered with just {name: 'as'}
// 2. When the AMap.Autocomplete autoOptions.type contains multiple code like
// '190102|190104'(see amap_module.js) and input '南瓜' also can get result from AMap.
// 3. If the selected value is Provincial Place Name(see line below that says 'regProvince.exec(place.district)').
// In those cases, throw an invalid city error message to the user.
function throwInvalidCityError(onPlaceChanged) {
    onPlaceChanged({ place_id: '' }, { formatted_address: '', invalidCity: true });
    throw new Error('Please choose a city.');
}

// See https://github.com/ocombe/ocLazyLoad/issues/170
// See https://gist.github.com/VictorBjelkholm/6687484
function getPlaceAndDetails($injector, event, districtSearch, onPlaceChanged) {
    const ErrorLogService = $injector.get('ErrorLogService');
    const $rootScope = $injector.get('$rootScope');

    // get the auto-completed place info
    const place = event.poi;

    const placeDetails = {};

    if (!place.id && !place.adcode) {
        throwInvalidCityError(onPlaceChanged);
    }

    if (place.adcode) {
        placeDetails.adcode = place.adcode;
    }

    if (place.district) {
        // eslint-disable-next-line prefer-regex-literals
        const regProvince = new RegExp('(\\S+省|自治区)$');
        // Since Provincial Place Name is enabled in search type(see amap_module.js) and we only want
        // the 4 municipal cities, filter other provincial names here.
        if (regProvince.exec(place.district)) {
            throwInvalidCityError(onPlaceChanged);
        }
        // Match province and city from AMap district pattern
        // e.g. 江苏省南京市
        // eslint-disable-next-line prefer-regex-literals
        const reg = new RegExp('(\\S+省)?(\\S+市)');
        const match = reg.exec(place.district);

        if (match) {
            if (match[2]) {
                placeDetails.locality = {
                    short: match[2],
                    long: match[2],
                };
            }
            if (match[1]) {
                placeDetails.administrative_area_level_1 = {
                    short: match[1],
                    long: match[1],
                };
            }
        }
    }

    // Hard code country to China for all AMap users
    placeDetails.country = {
        short: 'CN',
        long: 'China',
    };

    if (place.location) {
        place.place_id = place.id;

        // normalize the address_components for DB indexing
        const location = place.location;

        // pluck out the lat and lon from for matchmaking
        if (location.lat && location.lng) {
            placeDetails.lat = location.lat;
            placeDetails.lng = location.lng;
        } else {
            ErrorLogService.notify('Place has no lat/lng', undefined, {
                user_id: $rootScope.currentUser.id,
                place_id: place && place.place_id,
            });
        }

        // this is convenient for display purposes, etc
        place.formatted_address = place.district + place.name;
        placeDetails.formatted_address = place.formatted_address;

        // in lieu of requiring a separate time-zone input ...
        // placeDetails.utc_offset = place.utc_offset_minutes || place.utc_offset;

        // We started collecting this with student network events
        placeDetails.name = place.name;
        // placeDetails.adr_address = place.adr_address;

        if (!place.place_id) {
            ErrorLogService.notify('Missing place_id', undefined, {
                user_id: $rootScope.currentUser.id,
            });
        }

        if (!place || !placeDetails) {
            return;
        }

        onPlaceChanged(place, placeDetails);

        return;
    }

    districtSearch.search(place.adcode, (status, result) => {
        if (result.info !== 'OK') {
            return;
        }

        place.place_id = place.adcode;

        // normalize the address_components for DB indexing
        if (result.districtList) {
            const district = result.districtList[0];
            // place.address_components.forEach(component => {
            //     placeDetails[component.types[0]] = {
            //         short: component.short_name,
            //         long: component.long_name,
            //     };
            // });

            // pluck out the lat and lon from for matchmaking
            if (district && district.center && district.center.lat && district.center.lng) {
                placeDetails.lat = district.center.lat;
                placeDetails.lng = district.center.lng;
            } else {
                ErrorLogService.notify('Place has no lat/lng', undefined, {
                    user_id: $rootScope.currentUser.id,
                    place_id: place && place.place_id,
                });
            }

            // this is convenient for display purposes, etc
            place.formatted_address = place.district ? place.district : place.name;
            placeDetails.formatted_address = place.formatted_address;

            // in lieu of requiring a separate time-zone input ...
            // placeDetails.utc_offset = place.utc_offset_minutes || place.utc_offset;

            // We started collecting this with student network events
            placeDetails.name = place.name;
            // placeDetails.adr_address = place.adr_address;

            // When the selected address is a municipality, the locality is empty, need to set it as the place name.
            if (!placeDetails.locality) {
                placeDetails.locality = {
                    short: place.name,
                    long: place.name,
                };
            }

            if (!place.place_id) {
                ErrorLogService.notify('Missing place_id', undefined, {
                    user_id: $rootScope.currentUser.id,
                });
            }
        }

        if (!place || !placeDetails) {
            return;
        }

        onPlaceChanged(place, placeDetails);
    });
}

export default function attachAMapAutoCompleteToInput({
    $injector,
    input,
    options,
    onAMapAutoCompleteInitialized,
    onPlaceChanged,
}) {
    options = options || {};
    onAMapAutoCompleteInitialized = onAMapAutoCompleteInitialized || function () {};

    let autoComplete;
    let districtSearch;

    if (!$injector) {
        throw new Error('No injector provider.  You probably need to set up AngularContext.');
    }

    const ErrorLogService = $injector.get('ErrorLogService');
    const ConfigFactory = $injector.get('ConfigFactory');
    const amap_js_api_key = ConfigFactory.getSync(true).amap_js_api_key;
    const amapSecurityJsCode = ConfigFactory.getSync(true).amap_js_api_code;
    const mapConfig = $injector.get('AMAP_CONFIG');

    aMapLoaderWrapper(
        {
            key: amap_js_api_key,
            version: mapConfig.version,
            plugins: mapConfig.plugins,
        },
        amapSecurityJsCode,
    )
        .then(AMap => {
            onAMapAutoCompleteInitialized();

            const autoOptions = {
                input,
                type: mapConfig.poi_type_city,
                datatype: 'all',
                ...options.autoCompleteOptions,
            };

            const searchOptions = {
                extensions: 'base',
                level: 'city',
                subdistrict: 0,
                showbiz: false,
            };

            autoComplete = new AMap.Autocomplete(autoOptions);
            districtSearch = new AMap.DistrictSearch(searchOptions);

            autoComplete.on('select', event => {
                getPlaceAndDetails($injector, event, districtSearch, onPlaceChanged);
            });
        })
        .catch(e => {
            logAMapLoadError(ErrorLogService, e);
        });

    return function cancelListener() {
        if (autoComplete) {
            autoComplete.closeResult();
        }
    };
}

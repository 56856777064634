import { type CohortClass } from 'Cohorts';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import getEnrollmentSidebarTodoGroups from 'Lessons/angularModule/scripts/stream/getEnrollmentSidebarTodoStuff';
import { GraduationStatus, getGraduationStatus, getHasOfferOrIncludedOrCompleted } from 'Users';
import { type TodoItem } from './StudentDashboard.types';

export const shouldShowEnrollment = ($injector: ng.auto.IInjectorService) => {
    const user = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
    if (!user) return false;

    const Cohort = $injector.get<CohortClass>('Cohort');
    const gradStatus = getGraduationStatus(user);

    // Reasons not to show the Enrollment sidebar box
    if (
        user.isAuditing ||
        !user.programType ||
        !Cohort.supportsEnrollmentSidebar(user.programType) ||
        !getHasOfferOrIncludedOrCompleted(user) ||
        (gradStatus && gradStatus !== GraduationStatus.pending) ||
        // user.needsToRegister should be false because we want registration to take
        // priority over enrollment. After they've registered, then show the enrollment sidebar
        user.needsToRegister ||
        !user.relevantCohort
    )
        return false;

    const [todoItems, _todoItemsByDueDate] = getEnrollmentSidebarTodoGroups($injector, {
        isLoadingBioSig: false,
        // we're only getting the items in order to read the isVisible() and hasBeenCompleted() properties,
        // so we can pass dummy values for this other arg, which is only used in an item's onClick()
        enrollBioSig: () => {
            /** no op */
        },
    }) as [TodoItem[], { [dueBy: string]: TodoItem[] }];

    const todosToComplete = todoItems.filter(
        (todoItem: TodoItem) => todoItem.isVisible() && !todoItem.hasBeenCompleted(),
    );

    const onlyHasToCompleteIdVerification =
        todosToComplete.length === 1 && todosToComplete[0].name === 'todo_id_verify';

    // When the only thing to do is id verification, the enrollment box is unnecessary because we're already
    // showing the id verification box
    const hasTodosToShowInList = !!todosToComplete.length && !onlyHasToCompleteIdVerification;

    return hasTodosToShowInList;
};

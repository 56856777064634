import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { AoiRecordType } from 'ProgramAoi';
import { AdmissionOfferStatus } from 'AdmissionOffer';
import { ProgramInclusionStatus, hasPreGraduationStatus } from 'ProgramInclusion';
import { getProgramConfigValue, DELIVERED_DIGITAL_CERTIFICATE, PHYSICAL_DIPLOMA } from 'Program';
import { getAoiRecord, getNotJoiningProgram, getCohort } from 'Users';
import { type CohortClass } from 'Cohorts';
import { type ErrorLogService as ErrorLogServiceClass } from 'ErrorLogging';
import { isPendingProgramApplication } from 'ProgramApplication';
import { StudentDashboardStateKey } from './StudentDashboard.types';

/**
 * Determine the user's state in order to display the correct UI on their student dashboard.
 * @param { FrontRoyalRootScope['currentUser']} [currentUser] the logged-in user viewing their student dashboard
 * @returns {StudentDashboardStateKey | null}
 */
export default function determineStudentDashboardStateKey(
    currentUser: FrontRoyalRootScope['currentUser'],
    $injector: ng.auto.IInjectorService,
) {
    if (!currentUser) {
        return null;
    }

    const Cohort = $injector.get<CohortClass>('Cohort');
    const ErrorLogService = $injector.get<typeof ErrorLogServiceClass>('ErrorLogService');

    const programApplication = getAoiRecord(currentUser, AoiRecordType.ProgramApplication);
    const admissionOffer = getAoiRecord(currentUser, AoiRecordType.AdmissionOffer);
    const programInclusion = getAoiRecord(currentUser, AoiRecordType.ProgramInclusion);
    const cohort = getCohort(currentUser);

    if (
        !cohort ||
        (cohort && !getProgramConfigValue(cohort.programType, 'supportsStudentDashboardConfig')) ||
        !currentUser.career_profile
    ) {
        return null;
    }

    const pendingApplication = !!programApplication && isPendingProgramApplication(programApplication);
    const notJoiningProgram = getNotJoiningProgram(currentUser);

    if (
        programInclusion?.status === ProgramInclusionStatus.Included &&
        !getProgramConfigValue(programInclusion.programType, 'supportsProgramApplications')
    ) {
        return StudentDashboardStateKey.registered_no_application;
    }

    if (programInclusion?.status === ProgramInclusionStatus.Graduated) {
        if (Cohort.supportsCohortPreApproval(programInclusion.programType)) {
            return StudentDashboardStateKey.graduated_pre_approval;
        }

        return StudentDashboardStateKey.graduated;
    }

    if (programInclusion?.status === ProgramInclusionStatus.WillNotGraduate) {
        return StudentDashboardStateKey.will_not_graduate;
    }

    if (programInclusion?.status === ProgramInclusionStatus.Included) {
        if (hasPreGraduationStatus(programInclusion, cohort)) {
            // We would like to combine these keys. See FIXME near the definition of pre_graduation_digital_cert in studentDashboardConfig.
            if (getProgramConfigValue(cohort.programType, 'completionDocumentType') === DELIVERED_DIGITAL_CERTIFICATE) {
                return StudentDashboardStateKey.pre_graduation_digital_cert;
            }

            if (getProgramConfigValue(cohort.programType, 'completionDocumentType') === PHYSICAL_DIPLOMA) {
                return StudentDashboardStateKey.pre_graduation;
            }

            ErrorLogService.notifyInProd('In pre_graduation state with unexpected completion document type.', null);
            return StudentDashboardStateKey.pre_graduation;
        }

        if (programInclusion.current) {
            return StudentDashboardStateKey.registered_current;
        }

        return StudentDashboardStateKey.registered_not_current;
    }

    // States specific to cohorts with `supportsCohortPreApproval = true` (e.g. ExecEd)
    // These two states occurs when a user clicked the "Review and Register" button on the "congrats modal"
    // for a particular ExecEd cert, which generates a program application and an admission offer.
    if (!!admissionOffer && Cohort.supportsCohortPreApproval(admissionOffer.programType)) {
        // When a user generates their admission offer for an ExecEd cert, but doesn't immediately register to the program,
        // they will see this dashboard state.
        if (admissionOffer.status === AdmissionOfferStatus.OfferedAdmission) {
            return StudentDashboardStateKey.unregistered_pre_approval;
        }

        // When a user generates their admission offer for an ExecEd cert, but doesn't register to the program within the admission round,
        // their records will be marked as declined and they will see this dashboard state instead.
        if (admissionOffer.status === AdmissionOfferStatus.DeclinedAdmissionOffer) {
            return StudentDashboardStateKey.declined_pre_approval;
        }
    }

    if (admissionOffer?.status === AdmissionOfferStatus.OfferedAdmission) {
        return StudentDashboardStateKey.applied_admitted;
    }

    if (notJoiningProgram && currentUser.canCurrentlySubmitProgramApplication) {
        return StudentDashboardStateKey.not_joining_program_can_reapply;
    }

    if (notJoiningProgram && !currentUser.canCurrentlySubmitProgramApplication) {
        return StudentDashboardStateKey.not_joining_program_cannot_reapply;
    }

    if (pendingApplication && programApplication.willNotInterview) {
        return StudentDashboardStateKey.applied_will_not_be_invited_to_interview;
    }

    if (pendingApplication && programApplication.interviewConducted) {
        return StudentDashboardStateKey.applied_conducted_interview;
    }

    if (pendingApplication && programApplication.invitedToPreRecordedInterview) {
        return StudentDashboardStateKey.applied_invited_to_pre_recorded_interview;
    }

    if (pendingApplication && programApplication.invitedToInterview && !programApplication.interviewScheduled) {
        return StudentDashboardStateKey.applied_invited_to_interview;
    }

    if (pendingApplication && programApplication.interviewScheduled) {
        return StudentDashboardStateKey.applied_scheduled_interview;
    }

    if (pendingApplication) {
        return StudentDashboardStateKey.applied;
    }

    return StudentDashboardStateKey.signup;
}

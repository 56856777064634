import { type AnyObject } from '@Types';
import { studentDashboardConfig } from 'AdmissionsGuidance';
import { type OfflineModeManager } from 'OfflineMode';
import showModalIfNecessary from './showModalIfNecessary';

// This class is responsible for keeping track of information that is global over
// the lifetime of the app. Any logic that does not need to track global state can
// be moved to functions
export default class AdmissionsProcessModalsViewModel {
    preventModalFromShowing = false;
    #injector: angular.auto.IInjectorService;
    #rootScope: angular.IRootScopeService;
    #location: angular.ILocationService;
    #offlineModeManager: OfflineModeManager;
    visitedPaths: AnyObject<AnyObject<boolean>> = {};

    constructor($injector: angular.auto.IInjectorService) {
        this.#injector = $injector;
        this.#rootScope = $injector.get('$rootScope');
        this.#location = $injector.get('$location');
        this.#offlineModeManager = $injector.get('offlineModeManager');
        this.listenForRouteChange();
    }

    private listenForRouteChange() {
        this.#rootScope.$on('$routeChangeSuccess', () => this.handleRouteChange());
    }

    private handleRouteChange() {
        studentDashboardConfig(this.#injector)?.modals?.forEach(modalRouteConfig => {
            showModalIfNecessary({
                viewModel: this,
                modalRouteConfig,
                injector: this.#injector,
                path: this.#location.path(),
                inOfflineMode: this.#offlineModeManager.inOfflineMode,
            });
        });
    }
}

import { type BrandConfig, type AdmittedStudentInfo } from 'AppBranding';
import { type TranslationHelperClass } from 'Translation';
import { navigationHelper } from 'navigationHelper';
import { type EventLogger } from 'EventLogger';
import { type DialogModal } from 'DialogModal';
import { type ProfessionalOrganization } from 'CareerProfiles';
import { type CohortIguanaObject, type CohortClass } from 'Cohorts';
import { type AnyFunction, type AnyObject } from '@Types';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { type ProgramInclusion, CurriculumStatus } from 'ProgramInclusion';
import { getAoiRecord, getUserProgramState, type BaseUser, type CurrentUserIguanaObject } from 'Users';
import { SCHEDULE_ADMISSIONS_CHAT_LINK } from 'AdmissionsHelp/constants';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { type ExecEdEligibilityBundle, ExecEdEligibilityBundleBox } from 'ExecEdEligibility';
import {
    ProgramTypeConfigs,
    showsCertificateCheckInWhenInFinishedState,
    showsDiplomaCheckInWhenInFinishedState,
} from 'Program';
import LateProjectSubmissionTemplate from 'AdmissionsGuidance/angularModule/views/late_project_submission_box.html';
import { AoiRecordType } from 'ProgramAoi';
import { isPendingProgramApplication } from 'ProgramApplication';
import { type SidebarBoxButton, SidebarBoxKey, type SidebarBoxConfig } from './StudentDashboard.types';
import admittedStudentsZoomConfig from './admittedStudentsZoomConfig';
import { upcomingEventsConfig, thisWeekInSlackConfig, scheduleStatusConfig, scheduleConfig } from './ScheduleBoxes';
import angularModule from './angularModule/scripts/admissions_guidance_module';
import { EnrollmentBox } from './EnrollmentBox/EnrollmentBox';
import { IdVerificationBox } from './IdVerificationBox/IdVerificationBox';
import { OfflineBox } from './OfflineBox/OfflineBox';
import { ScheduleInterviewBox } from './ScheduleInterviewBox/ScheduleInterviewBox';
import { showExecEdEligibilityBundle } from './showExecEdEligibilityBundle';

type Props = {
    $injector: ng.auto.IInjectorService;
    userBrandConfig: BrandConfig;
    admissionsSupportEmail: string;
    featuredWorkExperienceCompany: ProfessionalOrganization | null;
    inOfflineMode: boolean;
    admittedStudentInfo: AdmittedStudentInfo;
    timezone: string | null;
    chinaRegionMode: boolean;
    relevantCohort: CohortIguanaObject;
    curriculumStatus: CurriculumStatus | null;
    activeProgramInclusion: ProgramInclusion | null;
    userName: CurrentUserIguanaObject['name'];
    mailingAddress: CurrentUserIguanaObject['mailingAddress'];
    inStudentNetwork: boolean;
    preApprovalBundleActivated: boolean;
    filteredEligibilityBundle: ExecEdEligibilityBundle | null;
    showEnrollment: boolean;
    showIdentityVerification: boolean;
};

const recentCoursesTemplate = cacheAngularTemplate(
    angularModule,
    'AdmissionsGuidance/RecentCoursesBoxDesktop',
    `<course-list-box
        courses="recentCourses"
        open-stream-dashboard="openStreamDashboard"
        stream-available="streamAvailable"
        event-name="'student-dashboard:recent-courses'">
        </course-list-box>`,
);

const lateProjectSubmissionTemplate = cacheAngularTemplate(
    angularModule,
    'AdmissionsGuidance/LateProjectSubmission',
    LateProjectSubmissionTemplate,
);

angularModule.component(
    'execEdEligibilityBundleBox',
    react2Angular(ExecEdEligibilityBundleBox, ['filteredEligibilityBundle', 'showCertCongratsModal'], '', false),
);
angularModule.component('enrollmentBox', react2Angular(EnrollmentBox, [], '', false));
angularModule.component('idVerificationBox', react2Angular(IdVerificationBox, [], '', false));
angularModule.component('offlineBox', react2Angular(OfflineBox, [], '', false));
angularModule.component('scheduleInterviewBox', react2Angular(ScheduleInterviewBox, ['preRecorded'], '', false));

// This function is called from studentDashboardConfig, which is memoized. For that reason, this function is
// intended to return the same value whenever it is called with the same arguments. So, all arguments here should
// be immutable except for $injector. For $injector, so long as we only pull things from there that do not have any
// changing state, we should be ok.
const sidebarBoxConfigs = ({
    $injector,
    userBrandConfig,
    admissionsSupportEmail,
    featuredWorkExperienceCompany,
    inOfflineMode,
    admittedStudentInfo,
    timezone,
    chinaRegionMode,
    relevantCohort,
    curriculumStatus,
    activeProgramInclusion,
    userName,
    mailingAddress,
    inStudentNetwork,
    preApprovalBundleActivated,
    filteredEligibilityBundle,
    showEnrollment,
    showIdentityVerification,
}: Props): Record<SidebarBoxKey, SidebarBoxConfig> => {
    // See note above this method about memoization before pulling more things from the injector
    const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
    const EventLogger = $injector.get<EventLogger>('EventLogger');
    const DialogModal = $injector.get<DialogModal>('DialogModal');
    const formatsText = $injector.get<AnyObject<(text: string) => string>>('FormatsText');
    const Cohort = $injector.get<CohortClass>('Cohort');

    const translationHelper = new TranslationHelper('lessons.stream.student_dashboard_boxes');
    const navigationHelpers = navigationHelper($injector);
    const { loadUrl, loadRoute, loadFaqUrl } = navigationHelpers;

    const { friendly_name: friendlyName, text } = featuredWorkExperienceCompany || {};
    const featuredCompanyName = friendlyName || text || '';
    const pastDueProjects = activeProgramInclusion?.pastDueProjects ?? [];

    return {
        [SidebarBoxKey.admissions_info]: {
            key: SidebarBoxKey.admissions_info,
            heading: translationHelper.get('heading_admissions_info'),
            desktopContentTemplate: 'AdmissionsGuidance/admissionsInfoBoxDesktop',
            mobileContentTemplate: 'AdmissionsGuidance/admissionsInfoBoxMobile',
            mobileExpandable: true,
            mobileCtaButtonTextOverride: translationHelper.get('contact_us'),
            shouldHide: inOfflineMode,
            scope: {
                inOfflineMode,
                locales: {
                    email: admissionsSupportEmail,
                    schedule_video_chat: SCHEDULE_ADMISSIONS_CHAT_LINK,
                },
                notAvailableWhileOfflineLocale: translationHelper.get('not_available_while_offline'),
                onClick: (target: string, type: string) => {
                    EventLogger.log('student_dashboard_sidebar_box:clicked_button', {
                        type: `admissions_info:${type}`,
                    });

                    let fullUrl = target;

                    if (target.includes('@')) {
                        fullUrl = `mailto:${target}`;
                    }

                    loadUrl(fullUrl, '_blank');
                },
            },
        },
        [SidebarBoxKey.admissions_timeline]: {
            key: SidebarBoxKey.admissions_timeline,
            heading: '',
            buttons: [],
            shouldHide: true, // FIXME: convert student_dashboard_program_box
        },
        [SidebarBoxKey.admitted_students_zoom]: admittedStudentsZoomConfig({
            admittedStudentInfo,
            translationHelper,
            loadUrl,
        }),
        [SidebarBoxKey.company_tuition_funding]: {
            key: SidebarBoxKey.company_tuition_funding,
            heading: translationHelper.get('heading_company_funding', {
                featuredCompanyName,
            }),
            desktopContentHtml: translationHelper.get('content_company_funding', {
                featuredCompanyName,
                fundingEmailAddress: userBrandConfig?.emailAddressForUsername('funding'),
            }),
            buttons: [
                {
                    id: 'button_contact_us_company_funding',
                    text: translationHelper.get('button_contact_us'),
                    onClick: () => loadUrl(`mailto:${userBrandConfig?.emailAddressForUsername('funding')}`, '_blank'),
                },
            ],
        },
        [SidebarBoxKey.complete_application]: {
            key: SidebarBoxKey.complete_application,
            heading: translationHelper.get('heading_complete_application', {
                institutionName: userBrandConfig.brandNameShort,
            }),
            desktopContentHtml: translationHelper.get('content_complete_application'),
            buttons: [
                {
                    id: 'button_complete_application',
                    text: translationHelper.get('button_complete_application'),
                    onClick: () => '',
                },
            ],
            shouldHide: true, // FIXME: convert student_dashboard_program_box
        },
        [SidebarBoxKey.employer_funding]: {
            key: SidebarBoxKey.employer_funding,
            heading: translationHelper.get('heading_employer_funding'),
            desktopContentHtml: translationHelper.get('content_employer_funding', {
                fundingEmailAddress: userBrandConfig?.emailAddressForUsername('funding'),
            }),
            buttons: [
                {
                    id: 'button_contact_us_employer_funding',
                    text: translationHelper.get('button_contact_us'),
                    onClick: () => loadUrl(userBrandConfig?.fundingContactUrl, '_blank'),
                },
            ],
        },
        [SidebarBoxKey.find_company_students]: {
            key: SidebarBoxKey.find_company_students,
            heading: translationHelper.get('heading_find_company_students', {
                companyName: featuredCompanyName,
            }),
            desktopContentHtml: translationHelper.get('content_find_friends'),
            buttons: [
                {
                    id: 'button_view_students_and_alumni',
                    text: translationHelper.get('button_view_students_and_alumni'),
                    onClick: () => loadRoute('/student-network'),
                },
            ],
            shouldHide: inOfflineMode,
        },
        [SidebarBoxKey.find_friends]: {
            key: SidebarBoxKey.find_friends,
            heading: translationHelper.get('heading_find_friends', {
                institutionName: userBrandConfig?.brandNameShort,
            }),
            desktopContentHtml: translationHelper.get('content_find_friends'),
            buttons: [
                {
                    id: 'button_view_students_and_alumni',
                    text: translationHelper.get('button_view_students_and_alumni'),
                    onClick: () => loadRoute('/student-network'),
                },
            ],
            shouldHide: inOfflineMode,
        },
        [SidebarBoxKey.follow_us]: {
            key: SidebarBoxKey.follow_us,
            heading: translationHelper.get('heading_follow_us', {
                institutionName: userBrandConfig?.brandNameShort,
            }),
            desktopContentHtml: translationHelper.get('content_follow_us'),
            buttons: [
                {
                    id: 'button_follow_us',
                    text: translationHelper.get('button_follow_us'),
                    onClick: () => {
                        loadUrl(userBrandConfig?.followUsUrl, '_blank');
                    },
                },
            ],
        },
        // Whenever the identity_verification box is included in a sidebarBoxConfig, the enrollment
        // box should also be included, because identity verification information can show up in either
        // of those places depending on which other enrollment to do items there are
        [SidebarBoxKey.identity_verification]: {
            key: SidebarBoxKey.identity_verification,
            classes: ['has-custom-style'],
            desktopContentHtml: `
                <id-verification-box
                    injector="injector"
                ></id-verification-box>
            `,
            mobileExpandable: true,
            scope: {
                injector: $injector,
            },
            mobileHeadingOverride: translationHelper.get('heading_mobile_identity_verification'),
            mobileCtaButtonTextOverride: translationHelper.get('button_next_steps'),
            shouldHide: !showIdentityVerification,
        },
        [SidebarBoxKey.open_courses]: {
            key: SidebarBoxKey.open_courses,
            heading: translationHelper.get('heading_open_courses'),
            desktopContentHtml: ProgramTypeConfigs[relevantCohort.programType].openCoursesSidebarBoxContentKey
                ? translationHelper.get(ProgramTypeConfigs[relevantCohort.programType].openCoursesSidebarBoxContentKey!)
                : '',
            mobileExpandable: true,
            mobileCtaButtonTextOverride: translationHelper.get('button_more'),
        },
        [SidebarBoxKey.our_degree_programs]: {
            key: SidebarBoxKey.our_degree_programs,
            heading: translationHelper.get('heading_our_degree_programs'),
            desktopContentHtml: translationHelper.get('content_our_degree_programs', {
                institutionName: userBrandConfig?.brandNameShort,
            }),
            buttons: [
                {
                    id: 'button_the_mba',
                    text: translationHelper.get('button_the_mba'),
                    onClick: () => loadUrl(userBrandConfig?.learnMoreAboutMbaUrl, '_blank'),
                },
                {
                    id: 'button_the_executive_mba',
                    text: translationHelper.get('button_the_executive_mba'),
                    onClick: () => loadUrl(userBrandConfig?.learnMoreAboutEmbaUrl, '_blank'),
                },
                {
                    id: 'button_msba',
                    text: translationHelper.get('button_msba'),
                    onClick: () => loadUrl(userBrandConfig?.learnMoreAboutMsbaUrl, '_blank'),
                },
                {
                    id: 'button_msse',
                    text: translationHelper.get('button_msse'),
                    onClick: () => loadUrl(userBrandConfig?.learnMoreAboutMsseUrl, '_blank'),
                },
            ].filter(
                buttonConfig =>
                    !!buttonConfig && userBrandConfig?.ourDegreeProgramsSidebarBoxButtons?.includes(buttonConfig.id),
            ) as SidebarBoxButton[],
        },
        [SidebarBoxKey.our_tuition_model]: {
            key: SidebarBoxKey.our_tuition_model,
            heading: translationHelper.get('heading_our_tuition_model'),
            desktopContentHtml: translationHelper.get('content_our_tuition_model'),
            buttons: [
                {
                    id: 'button_learn_more',
                    text: translationHelper.get('button_learn_more'),
                    onClick: () => loadUrl(userBrandConfig?.learnMoreAboutTuitionUrl, '_blank'),
                },
            ],
        },
        [SidebarBoxKey.register_for_program]: {
            key: SidebarBoxKey.register_for_program,
            heading: '',
            buttons: [],
            shouldHide: true, // FIXME: convert student_dashboard_program_box
        },
        [SidebarBoxKey.not_joining_program_can_reapply]: {
            key: SidebarBoxKey.not_joining_program_can_reapply,
            heading: '',
            buttons: [],
            shouldHide: true, // FIXME: convert student_dashboard_program_box
        },
        [SidebarBoxKey.not_joining_program_cannot_reapply]: {
            key: SidebarBoxKey.not_joining_program_cannot_reapply,
            heading: '',
            buttons: [],
            shouldHide: true, // FIXME: convert student_dashboard_program_box
        },
        [SidebarBoxKey.schedule_your_interview]: {
            key: SidebarBoxKey.schedule_your_interview,
            classes: ['has-custom-style'],
            desktopContentHtml: `
                <schedule-interview-box
                    injector="injector"
                ></schedule-interview-box>
            `,
            mobileExpandable: true,
            mobileHeadingOverride: translationHelper.get('heading_mobile_schedule_interview'),
            mobileCtaButtonTextOverride: translationHelper.get('button_schedule'),
            scope: {
                injector: $injector,
            },
        },
        [SidebarBoxKey.pre_recorded_interview]: {
            key: SidebarBoxKey.pre_recorded_interview,
            classes: ['has-custom-style'],
            desktopContentHtml: `
                <schedule-interview-box
                    injector="injector"
                    pre-recorded="true"
                ></schedule-interview-box>
            `,
            mobileExpandable: true,
            mobileHeadingOverride: translationHelper.get('heading_mobile_conduct_interview'),
            mobileCtaButtonTextOverride: translationHelper.get('button_conduct_interview'),
            scope: {
                injector: $injector,
            },
        },
        [SidebarBoxKey.upload_documents]: {
            key: SidebarBoxKey.upload_documents,
            heading: translationHelper.get('heading_upload_documents'),
            desktopContentHtml: translationHelper.get('content_upload_documents'),
            buttons: [
                {
                    id: 'button_upload_documents',
                    text: translationHelper.get('heading_upload_documents'),
                    onClick: () => loadRoute('/settings/documents'),
                },
            ],
        },
        [SidebarBoxKey.view_upcoming_events]: {
            key: SidebarBoxKey.view_upcoming_events,
            heading: inStudentNetwork
                ? translationHelper.get('heading_view_upcoming_events')
                : translationHelper.get('heading_view_featured_events'),
            buttons: [
                {
                    id: 'button_view_events',
                    text: inStudentNetwork
                        ? translationHelper.get('button_view_events')
                        : translationHelper.get('button_view_featured_events'),
                    onClick: () =>
                        loadRoute(`/student-network?${inStudentNetwork ? 'show-events' : 'show-featured-events'}`),
                },
            ],
            shouldHide: inOfflineMode,
        },
        [SidebarBoxKey.upcoming_events]: upcomingEventsConfig({
            $injector,
            translationHelper,
            EventLogger,
            loadRoute,
            DialogModal,
            timezone,
            chinaRegionMode,
            supportsNetworkAccess: !!relevantCohort.supportsNetworkAccess,
            inOfflineMode,
            activeProgramInclusion,
            curriculumStatus,
        }),
        [SidebarBoxKey.this_week_in_slack]: thisWeekInSlackConfig({
            curriculumStatus,
            translationHelper,
            relevantCohort,
        }),
        [SidebarBoxKey.schedule]: scheduleConfig({
            TranslationHelper,
            relevantCohort,
            curriculumStatus,
            formatsText,
            loadUrl,
        }),
        [SidebarBoxKey.schedule_status]: scheduleStatusConfig({
            curriculumStatus,
            activeProgramInclusion,
            relevantCohort,
            navigationHelpers,
            userBrandConfig,
            translationHelper,
        }),
        [SidebarBoxKey.recent_courses]: {
            key: SidebarBoxKey.recent_courses,
            heading: translationHelper.get('heading_recent_courses'),
            desktopContentTemplate: recentCoursesTemplate,

            watchers: {
                showRecentStreams: (scope, showRecentStreams) => {
                    scope.hide = !showRecentStreams;
                },
            },
        },
        [SidebarBoxKey.diploma_check]: {
            key: SidebarBoxKey.diploma_check,
            heading: translationHelper.get('heading_diploma_check'),
            shouldHide: !showsDiplomaCheckInWhenInFinishedState(relevantCohort.programType),

            // We're assuming here that if supportsPhysicalDiploma is true, then you require a mailing address.
            // See Cohort#requiresMailingAddress. If that ever changes, we might need to tweak this.
            desktopContentHtml: `
                <h3>${userName}</h3>
                <h3 class="mailing-address">${mailingAddress}</h3>
                ${translationHelper.get('content_diploma_check')}
            `,
            buttons: [
                {
                    id: 'button_update_name',
                    text: translationHelper.get('button_update_name'),
                    onClick: () => loadRoute('/settings/account'),
                },
                {
                    id: 'button_update_address',
                    text: translationHelper.get('button_update_address'),
                    onClick: () => loadRoute('/settings/documents'),
                },
            ],
        },
        [SidebarBoxKey.digital_certificate_check]: {
            key: SidebarBoxKey.digital_certificate_check,
            shouldHide: !showsCertificateCheckInWhenInFinishedState(relevantCohort.programType),
            heading: translationHelper.get('heading_digital_certificate_check'),
            desktopContentHtml: `
                <h3>${userName}</h3>
                ${translationHelper.get('content_digital_certificate_check')}
            `,
            buttons: [
                {
                    id: 'button_update_name',
                    text: translationHelper.get('button_update_name'),
                    onClick: () => loadRoute('/settings/account'),
                },
            ],
        },
        [SidebarBoxKey.diploma_frames]: {
            key: SidebarBoxKey.diploma_frames,
            shouldHide: !relevantCohort.supportsSchedule || !relevantCohort.supportsPhysicalDiploma,
            heading: translationHelper.get('heading_diploma_frames'),
            desktopContentHtml: translationHelper.get('content_diploma_frames'),
            buttons: [
                {
                    id: 'button_browse_frames',
                    text: translationHelper.get('button_browse_frames'),
                    onClick: () => loadUrl('/links/diploma/frames', '_blank'),
                },
            ],
        },
        [SidebarBoxKey.enrollment]: {
            key: SidebarBoxKey.enrollment,
            classes: ['has-custom-style'],
            desktopContentHtml: `
                <enrollment-box
                    injector="injector"
                ></enrollment-box>
            `,
            mobileExpandable: true,
            scope: {
                injector: $injector,
            },
            mobileHeadingOverride: translationHelper.get('heading_enrollment'),
            mobileCtaButtonTextOverride: translationHelper.get('button_next_steps'),
            fullHeight: true,
            shouldHide: !showEnrollment,
        },
        [SidebarBoxKey.offline]: {
            key: SidebarBoxKey.offline,
            classes: ['has-custom-style', 'max-md:tw-h-[25px]'],
            mobileContainerClasses: ['tw-bg-black'],
            desktopContentHtml: `
                <offline-box
                    injector="injector"
                ></offline-box>
            `,
            scope: {
                injector: $injector,
            },
            mobileHeadingOverride: translationHelper.get('heading_mobile_offline'),
            shouldHide: !inOfflineMode,
            hideMobileCta: true,
        },
        [SidebarBoxKey.transcripts]: {
            key: SidebarBoxKey.transcripts,
            // FIXME: ExecEd programs won't display transcript information at launch, but may in the future
            shouldHide: !relevantCohort.supportsSchedule || relevantCohort.isExecEd,
            heading: translationHelper.get('heading_transcripts'),
            desktopContentHtml: translationHelper.get('content_transcripts'),
            buttons: [
                {
                    id: 'button_learn_more',
                    text: translationHelper.get('button_learn_more'),
                    onClick: () => loadFaqUrl('/help/how-can-i-request-my-transcript', '_blank'),
                },
            ],
        },
        [SidebarBoxKey.graduated]: {
            key: SidebarBoxKey.graduated,
            classes: ['has-custom-style'],
            // program-achievement-graphic is a react2Angular component created in this module file
            desktopContentHtml: `
                <program-achievement-graphic
                    variant="'graduated'"
                    current-user="currentUser"
                    xs-or-sm="xsOrSm"
                    collapsed="!mobileExpanded && xsOrSm"
                ></program-achievement-graphic>
            `,
            mobileExpandable: true,
            mobileHeadingOverride: translationHelper.get('heading_congrats_you_have_graduated'),
            mobileCtaButtonTextOverride: translationHelper.get('button_share'),
            fullHeight: true,
        },
        [SidebarBoxKey.accepted]: {
            key: SidebarBoxKey.accepted,
            classes: ['has-custom-style'],
            // program-achievement-graphic is a react2Angular component created in this module file
            desktopContentHtml: `
                <program-achievement-graphic
                    variant="'accepted'"
                    collapsed="!mobileExpanded && xsOrSm"
                    current-user="currentUser"
                    xs-or-sm="xsOrSm"
                ></program-achievement-graphic>
            `,
            mobileExpandable: true,
            mobileHeadingOverride: translationHelper.get('heading_accepted'),
            mobileCtaButtonTextOverride: translationHelper.get('button_share'),
            fullHeight: true,
            shouldHide: !!activeProgramInclusion?.auditing || !!relevantCohort.supportsCohortPreApproval,
        },
        [SidebarBoxKey.pre_approved_for_cohort]: {
            key: SidebarBoxKey.pre_approved_for_cohort,
            // exec-ed-eligibility-bundle-box is a react2Angular component created in showExecEdEligibilityBundle.ts.
            // If we ever have another set of pre-approved programs, we would have to replace this component with a more general one
            desktopContentHtml: `
                <exec-ed-eligibility-bundle-box
                    filtered-eligibility-bundle="filteredEligibilityBundle"
                    show-cert-congrats-modal="showCertCongratsModal"
                ></exec-ed-eligibility-bundle-box>
            `,
            scope: {
                filteredEligibilityBundle,
                showCertCongratsModal: () => showExecEdEligibilityBundle($injector),
            },
            watchers: {
                currentUser: scope => {
                    // Determine if the application accepted message should be displayed
                    const ups = getUserProgramState(scope.currentUser as BaseUser);
                    const activeProgramTypeConfig = Cohort.programTypes.find(
                        ptConfig => ptConfig.key === ups?.programType,
                    );
                    const currentUser = scope.currentUser as CurrentUserIguanaObject;
                    const _showCertCongratsModal = scope.showCertCongratsModal as AnyFunction;
                    const programApplication = getAoiRecord(currentUser, AoiRecordType.ProgramApplication);
                    const pendingApplication = !!programApplication && isPendingProgramApplication(programApplication);

                    const shouldShowCertCongratsModal =
                        preApprovalBundleActivated &&
                        // We want users in the degree application funnel to see the exec ed bundle box, but without the
                        // cert congrats modal showing up on every page load.
                        !pendingApplication &&
                        // User's currently active UPS is not an ExecEd program
                        !activeProgramTypeConfig?.isExecEd &&
                        // User's eligibility bundle has at least one program to show
                        (!!filteredEligibilityBundle?.recommendedProgramType ||
                            !!filteredEligibilityBundle?.eligiblePrograms?.length) &&
                        // Ephemeral flag to prevent the modal from showing multiple times on the same page load.
                        !currentUser.hasSeenCertCongratsModalThisPageLoad;

                    if (shouldShowCertCongratsModal) {
                        _showCertCongratsModal();
                        currentUser.hasSeenCertCongratsModalThisPageLoad = true;
                    }
                },
            },
            mobileHeadingOverride: translationHelper.get('heading_executive_education'),
            shouldHide:
                !preApprovalBundleActivated ||
                (!filteredEligibilityBundle?.recommendedProgramType &&
                    !filteredEligibilityBundle?.eligiblePrograms?.length),
            buttons: [
                {
                    id: 'button_view_programs',
                    text: translationHelper.get('button_view_programs'),
                    onClick: () => showExecEdEligibilityBundle($injector),
                    hideOnDesktop: true,
                },
            ],
        },
        [SidebarBoxKey.late_project_submission]: {
            key: SidebarBoxKey.late_project_submission,
            heading: translationHelper.get(
                'heading_late_project_submission',
                {
                    num: pastDueProjects.length,
                },
                undefined,
                'messageformat',
            ),
            scope: {
                pastDueProjects,
            },
            desktopContentTemplate: lateProjectSubmissionTemplate,
            // This `late_project_submission` box and the `schedule_status` box (when in a NotOnTrack state) both utilize the `past-due-projects-list` directive
            // to display the list of past due projects on the student's dashboard. The `late_project_submission` box is only shown when project(s) from one period
            // ago are past due. If any projects are 2+ periods past due, the user will be in a `NotOnTrack` state and the `schedule_status` box will be shown instead.
            // More info: https://trello.com/c/AgwLmvKJ
            shouldHide: pastDueProjects.length === 0 || curriculumStatus === CurriculumStatus.NotOnTrack,
        },
    };
};

export default sidebarBoxConfigs;

import { type RoleName } from 'Users';
import {
    type MobileSidebarBoxes,
    type SidebarBoxConfig,
    type SidebarBoxKey,
    type StudentDashboardConfig,
} from './StudentDashboard.types';

type SideBarBoxes = Record<SidebarBoxKey, SidebarBoxConfig>;
type AdminRole = Extract<RoleName, 'admin' | 'interviewer' | 'super_editor'>;
type AdminConfig = Record<
    AdminRole,
    Pick<StudentDashboardConfig, 'desktopSidebarBoxes' | 'mobileSidebarBoxes' | 'mobileContentBoxes'>
>;

const adminRoles: AdminRole[] = ['admin', 'interviewer', 'super_editor'];

const isAdmin = (role: RoleName): role is AdminRole => adminRoles.includes(role as AdminRole);

const removeDuplicateSideBarBoxes = (boxes: SidebarBoxConfig[]) => [
    ...new Map(boxes.map(conf => [conf.key, conf])).values(),
];

const getAdminConfig = (sidebarBoxes: SideBarBoxes): AdminConfig => ({
    admin: {
        mobileSidebarBoxes: async () => [],
        desktopSidebarBoxes: async () => [sidebarBoxes.recent_courses],
    },
    interviewer: {
        mobileSidebarBoxes: async () => [],
        desktopSidebarBoxes: async () => [sidebarBoxes.recent_courses],
    },
    super_editor: {
        mobileSidebarBoxes: async () => [],
        desktopSidebarBoxes: async () => [sidebarBoxes.recent_courses],
    },
});

export const updateDashboardConfigForAdmins = (
    role: RoleName,
    config: StudentDashboardConfig,
    sidebarBoxes: SideBarBoxes,
) => {
    if (!isAdmin(role)) return config;

    const {
        mobileSidebarBoxes: mobileBoxes,
        desktopSidebarBoxes: desktopBoxes,
        mobileContentBoxes: contentBoxes,
    } = config;

    const {
        mobileSidebarBoxes: adminMobileBoxes,
        desktopSidebarBoxes: adminDesktopBoxes,
        mobileContentBoxes: adminContentBoxes,
    } = getAdminConfig(sidebarBoxes)[role];

    config.mobileSidebarBoxes = async () => {
        const boxes = await mobileBoxes();
        const adminBoxes = await adminMobileBoxes();
        return removeDuplicateSideBarBoxes([...boxes, ...adminBoxes]) as MobileSidebarBoxes;
    };

    config.desktopSidebarBoxes = async () => {
        const boxes = await desktopBoxes();
        const adminBoxes = await adminDesktopBoxes();
        return removeDuplicateSideBarBoxes([...boxes, ...adminBoxes]);
    };

    config.mobileContentBoxes = removeDuplicateSideBarBoxes([...(contentBoxes || []), ...(adminContentBoxes || [])]);

    return config;
};

export default updateDashboardConfigForAdmins;

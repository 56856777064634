import angularModule from 'AMap/angularModule/scripts/amap_module';
import template from 'AMap/angularModule/views/amap.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import { aMapLoaderWrapper, logAMapLoadError } from 'AMap';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('aMap', () => ({
    templateUrl,
    restrict: 'E',
    transclude: true,
    scope: {
        zoom: '<',
        center: '<',
    },
    controller: [
        '$scope',
        '$injector',
        function (scope, $injector) {
            const ConfigFactory = $injector.get('ConfigFactory');
            const ErrorLogService = $injector.get('ErrorLogService');
            const amapWebKey = ConfigFactory.getSync(true).amap_js_api_key;
            const amapSecurityJsCode = ConfigFactory.getSync(true).amap_js_api_code;
            const mapConfig = $injector.get('AMAP_CONFIG');

            aMapLoaderWrapper(
                {
                    key: amapWebKey,
                    version: mapConfig.version,
                    AMapUI: mapConfig.amap_ui,
                },
                amapSecurityJsCode,
            )
                .then(AMap => {
                    const opt = {
                        center: scope.center,
                        zoom: scope.zoom,
                        zoomEnable: true,
                    };

                    this.map = new AMap.Map('amap-container', opt);
                    scope.map = this.map;

                    scope.$watch('center', (newValue, oldValue) => {
                        if (newValue !== oldValue) {
                            scope.map.setCenter(newValue);
                        }
                    });
                })
                .catch(e => {
                    logAMapLoadError(ErrorLogService, e);
                });
        },
    ],
}));

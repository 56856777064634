import { faPlane } from '@fortawesome/pro-solid-svg-icons/faPlane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';

export function OfflineBox() {
    return <OfflineBoxPropsWrapper />;
}

function OfflineBoxPropsWrapper() {
    const { xsOrSm } = useIsMobileMixin();
    return <OfflineBoxPresentation xsOrSm={xsOrSm} />;
}

export function OfflineBoxPresentation({ xsOrSm }: { xsOrSm: boolean }) {
    const { t } = useTranslation('back_royal');

    if (xsOrSm) return null;

    // DESKTOP VIEW
    return (
        <div data-testid="content-container" className="tw-bg-grey-dark-blue tw-px-5 tw-py-[30px]">
            <h2 className="tw-mb-5 tw-flex tw-flex-col tw-items-center tw-justify-center">
                <span className="tw-mb-[5px] tw-block tw-text-xs tw-font-normal tw-uppercase tw-text-white">
                    {t('lessons.stream.student_dashboard_offline_box.you_are')}
                </span>
                <span className="tw-mb-[15px] tw-block tw-text-[28px] tw-font-semibold tw-uppercase tw-text-white">
                    {t('lessons.stream.student_dashboard_offline_box.offline')}
                </span>
                <span className="tw-flex tw-h-[40px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-full tw-bg-orange tw-text-lg tw-font-normal tw-text-white">
                    <FontAwesomeIcon icon={faPlane} />
                </span>
            </h2>
            <p data-testid="sub-text" className="tw-mb-0 tw-text-center tw-text-sm tw-font-normal tw-text-white">
                {t('lessons.stream.student_dashboard_offline_box.keep_going')}
            </p>
        </div>
    );
}

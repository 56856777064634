import { type DialogModal } from 'DialogModal';
import { navigationHelper } from 'navigationHelper';
import type AdmissionsProcessModalsViewModel from './AdmissionsProcessModalsViewModel';

type Props = {
    path: string;
    injector: angular.auto.IInjectorService;
};

export default function loadRouteAndHideAlerts({ path, injector }: Props) {
    const { loadRoute } = navigationHelper(injector);
    const DialogModal = injector.get<DialogModal>('DialogModal');
    const viewModel = injector.get<AdmissionsProcessModalsViewModel>('admissionsProcessModalsViewModel');
    const timeout = injector.get<angular.ITimeoutService>('$timeout');
    const rootScope = injector.get<angular.IRootScopeService>('$rootScope');
    const currentPath = injector.get<angular.ILocationService>('$location').path();

    // If we are navigating to another page that will show the modal, we don't want it to
    // appear.
    if (path !== currentPath) {
        viewModel.preventModalFromShowing = true;
    }
    loadRoute(path);

    // wait until the route has changed and then bump the preventModalFromShowing boolean back to false
    const removeListener = rootScope.$on('$routeChangeSuccess', () => {
        timeout(() => {
            viewModel.preventModalFromShowing = false;
            removeListener();
        });
    });

    // For some reason, the hideAlerts is unnecessary on the dashboard, but needed on the
    // application page. In any case, it doesn't hurt to have it
    DialogModal.hideAlerts();
}

import memoizeOne from 'memoize-one';
import { isEqual } from 'lodash/fp';
import { type ProfessionalOrganization } from 'CareerProfiles';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular/FrontRoyal.types';
import {
    type RoleName,
    type CurrentUserIguanaObject,
    getProgramInclusion as baseGetProgramInclusion,
    getCurriculumStatus,
    getHasActivatedExecEdEligibilityBundle,
    getFilteredExecEdEligibilityBundle,
    getRelevantCohorts,
} from 'Users';
import { type Cohort, type CohortIguanaObject } from 'Cohorts';
import { targetBrandConfig } from 'AppBranding';
import { type BrandConfig, type AdmittedStudentInfo } from 'AppBranding';
import { type OfflineModeManager } from 'OfflineMode';
import { type Config as FrontRoyalConfig, type ConfigFactory } from 'FrontRoyalConfig';
import modalConfigs from 'AdmissionsProcessModals/modalConfigs';
import selectItemAtRandom from 'Utils/selectItemAtRandom';
import { type ProgramInclusion, CurriculumStatus } from 'ProgramInclusion';
import { type AdmissionOffer } from 'AdmissionOffer';
import { hasCompletedFoundations } from 'StoredProgress/playlistProgressUtils';
import { type ExecEdEligibilityBundle } from 'ExecEdEligibility';
import sidebarBoxConfigs from './sidebarBoxConfigs';
import { type StudentDashboardConfig, StudentDashboardStateKey } from './StudentDashboard.types';
import { updateDashboardConfigForAdmins } from './updateDashboardConfigForAdmins';
import determineStudentDashboardStateKey from './determineStudentDashboardStateKey';
import { shouldShowEnrollment } from './shouldShowEnrollment';
import { shouldShowIdVerification } from './shouldShowIdVerification';

// studentDashboardConfig is memoized. For that reason, it is
// intended to return the same value whenever it is called with the same arguments. So, all arguments here should
// be immutable except for $injector. For $injector, so long as we only pull things from there that do not have any
// changing state, we should be ok.
type Props = {
    stateKey: StudentDashboardStateKey;
    $injector: ng.auto.IInjectorService;
    topMessageLocaleKeyPrefix: CohortIguanaObject['topMessageLocaleKeyPrefix'];
    userBrandConfig: BrandConfig;
    featuredWorkExperienceCompany: ProfessionalOrganization | null;
    admissionsSupportEmail: string;
    inOfflineMode: boolean;
    admittedStudentInfo: AdmittedStudentInfo;
    role: RoleName;
    chinaRegionMode: boolean;
    timezone: string | null;
    relevantCohort: CohortIguanaObject;
    curriculumStatus: CurriculumStatus | null;
    locale: string;
    activeProgramInclusion: ProgramInclusion | null;
    userName: CurrentUserIguanaObject['name'];
    mailingAddress: CurrentUserIguanaObject['mailingAddress'];
    inStudentNetwork: boolean;
    preApprovalBundleActivated: boolean;
    filteredEligibilityBundle: ExecEdEligibilityBundle | null;
    showEnrollment: boolean;
    showIdentityVerification: boolean;
};

/**
 * Determine the program state for the user and provide a configuration for their
 * Student Dashboard UI, especially which boxes it should display in the sidebar.
 */
const buildStudentDashboardConfig = ({
    $injector,
    stateKey,
    topMessageLocaleKeyPrefix,
    userBrandConfig,
    featuredWorkExperienceCompany,
    admissionsSupportEmail,
    inOfflineMode,
    admittedStudentInfo,
    role,
    chinaRegionMode,
    timezone,
    relevantCohort,
    curriculumStatus,
    activeProgramInclusion,
    userName,
    mailingAddress,
    inStudentNetwork,
    preApprovalBundleActivated,
    filteredEligibilityBundle,
    showEnrollment,
    showIdentityVerification,
}: Props): StudentDashboardConfig | null => {
    // Build all the sidebar boxes according to the current user data
    const sidebarBoxes = sidebarBoxConfigs({
        $injector,
        userBrandConfig,
        admissionsSupportEmail,
        featuredWorkExperienceCompany,
        inOfflineMode,
        admittedStudentInfo,
        chinaRegionMode,
        timezone,
        relevantCohort,
        curriculumStatus,
        activeProgramInclusion,
        userName,
        mailingAddress,
        inStudentNetwork,
        preApprovalBundleActivated,
        filteredEligibilityBundle,
        showEnrollment,
        showIdentityVerification,
    });

    const modals = modalConfigs({ injector: $injector, userBrandConfig });
    const knownCompany = featuredWorkExperienceCompany?.employer_network || false;
    const findFriendsBox = knownCompany ? sidebarBoxes.find_company_students : sidebarBoxes.find_friends;
    const employerFundingBox = knownCompany ? sidebarBoxes.company_tuition_funding : sidebarBoxes.employer_funding;
    const topMessageLocaleKey = (suffix: string) =>
        topMessageLocaleKeyPrefix ? `${topMessageLocaleKeyPrefix}${suffix}` : undefined;
    const admissionsInfoOrOpenCourses = async (injector: ng.auto.IInjectorService) =>
        (await hasCompletedFoundations(injector)) ? sidebarBoxes.admissions_info : sidebarBoxes.open_courses;

    // Build the configs for each of the state keys
    const configs: Record<StudentDashboardStateKey, StudentDashboardConfig> = {
        [StudentDashboardStateKey.signup]: {
            stateKey: StudentDashboardStateKey.signup,
            mobileSidebarBoxes: async () =>
                preApprovalBundleActivated
                    ? [
                          sidebarBoxes.offline,
                          sidebarBoxes.complete_application,
                          sidebarBoxes.pre_approved_for_cohort,
                          await admissionsInfoOrOpenCourses($injector),
                      ]
                    : [
                          sidebarBoxes.offline,
                          sidebarBoxes.complete_application,
                          await admissionsInfoOrOpenCourses($injector),
                          selectItemAtRandom([findFriendsBox, sidebarBoxes.view_upcoming_events]),
                      ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.complete_application,
                sidebarBoxes.pre_approved_for_cohort,
                await admissionsInfoOrOpenCourses($injector),
                findFriendsBox,
                sidebarBoxes.our_degree_programs,
                knownCompany ? sidebarBoxes.company_tuition_funding : sidebarBoxes.our_tuition_model,
                sidebarBoxes.follow_us,
            ],
            modals: [{ route: '/settings/application', onlyShowOnce: true, modal: modals.welcomeToDashboard }],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop'),
            showDesktopProgramBox: true,
            showMobileProgramBox: true,
        },
        [StudentDashboardStateKey.applied]: {
            stateKey: StudentDashboardStateKey.applied,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                await admissionsInfoOrOpenCourses($injector),
                sidebarBoxes.pre_approved_for_cohort,
                selectItemAtRandom([findFriendsBox, sidebarBoxes.view_upcoming_events]),
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.admissions_timeline, // currently always hidden
                await admissionsInfoOrOpenCourses($injector),
                sidebarBoxes.pre_approved_for_cohort,
                findFriendsBox,
                sidebarBoxes.follow_us,
            ],
            modals: [{ route: '/dashboard', modal: modals.applicationReceived, onlyShowOnce: true }],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop_applied'),
            showDesktopProgramBox: true,
        },
        [StudentDashboardStateKey.applied_will_not_be_invited_to_interview]: {
            stateKey: StudentDashboardStateKey.applied_will_not_be_invited_to_interview,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.admissions_timeline, // currently always hidden
                sidebarBoxes.pre_approved_for_cohort,
                findFriendsBox,
                sidebarBoxes.view_upcoming_events,
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.admissions_timeline, // currently always hidden
                sidebarBoxes.pre_approved_for_cohort,
                findFriendsBox,
                sidebarBoxes.our_degree_programs,
                sidebarBoxes.follow_us,
            ],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop_applied'),
            showDesktopProgramBox: true,
            showMobileProgramBox: true,
        },
        [StudentDashboardStateKey.applied_invited_to_interview]: {
            stateKey: StudentDashboardStateKey.applied_invited_to_interview,
            mobileSidebarBoxes: async () =>
                preApprovalBundleActivated
                    ? [
                          sidebarBoxes.offline,
                          sidebarBoxes.schedule_your_interview,
                          await admissionsInfoOrOpenCourses($injector),
                          sidebarBoxes.pre_approved_for_cohort,
                      ]
                    : [
                          sidebarBoxes.offline,
                          sidebarBoxes.schedule_your_interview,
                          await admissionsInfoOrOpenCourses($injector),
                          selectItemAtRandom([findFriendsBox, sidebarBoxes.view_upcoming_events]),
                      ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.schedule_your_interview,
                await admissionsInfoOrOpenCourses($injector),
                sidebarBoxes.pre_approved_for_cohort,
                findFriendsBox,
                sidebarBoxes.follow_us,
            ],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop_applied'),
        },
        [StudentDashboardStateKey.applied_invited_to_pre_recorded_interview]: {
            stateKey: StudentDashboardStateKey.applied_invited_to_pre_recorded_interview,
            mobileSidebarBoxes: async () =>
                preApprovalBundleActivated
                    ? [
                          sidebarBoxes.offline,
                          sidebarBoxes.pre_recorded_interview,
                          await admissionsInfoOrOpenCourses($injector),
                          sidebarBoxes.pre_approved_for_cohort,
                      ]
                    : [
                          sidebarBoxes.offline,
                          sidebarBoxes.pre_recorded_interview,
                          await admissionsInfoOrOpenCourses($injector),
                          selectItemAtRandom([findFriendsBox, sidebarBoxes.view_upcoming_events]),
                      ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.pre_recorded_interview,
                await admissionsInfoOrOpenCourses($injector),
                sidebarBoxes.pre_approved_for_cohort,
                findFriendsBox,
                sidebarBoxes.follow_us,
            ],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop_applied'),
        },
        [StudentDashboardStateKey.applied_scheduled_interview]: {
            stateKey: StudentDashboardStateKey.applied_scheduled_interview,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                await admissionsInfoOrOpenCourses($injector),
                sidebarBoxes.pre_approved_for_cohort,
                selectItemAtRandom([findFriendsBox, sidebarBoxes.view_upcoming_events]),
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.admissions_timeline, // currently always hidden
                await admissionsInfoOrOpenCourses($injector),
                sidebarBoxes.pre_approved_for_cohort,
                findFriendsBox,
                sidebarBoxes.follow_us,
            ],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop_applied'),
            showDesktopProgramBox: true,
        },
        [StudentDashboardStateKey.applied_conducted_interview]: {
            stateKey: StudentDashboardStateKey.applied_conducted_interview,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                await admissionsInfoOrOpenCourses($injector),
                sidebarBoxes.pre_approved_for_cohort,
                selectItemAtRandom([findFriendsBox, sidebarBoxes.view_upcoming_events]),
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.admissions_timeline, // currently always hidden
                await admissionsInfoOrOpenCourses($injector),
                sidebarBoxes.pre_approved_for_cohort,
                sidebarBoxes.upload_documents,
                findFriendsBox,
                sidebarBoxes.follow_us,
            ],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop_applied'),
            showDesktopProgramBox: true,
        },
        [StudentDashboardStateKey.applied_admitted]: {
            stateKey: StudentDashboardStateKey.applied_admitted,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.register_for_program,
                sidebarBoxes.admitted_students_zoom,
                sidebarBoxes.admissions_info,
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.register_for_program,
                sidebarBoxes.admitted_students_zoom,
                sidebarBoxes.admissions_info,
                employerFundingBox,
                findFriendsBox,
                sidebarBoxes.follow_us,
            ],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop_applied'),
            showDesktopProgramBox: true,
            showMobileProgramBox: true,
        },
        [StudentDashboardStateKey.not_joining_program_cannot_reapply]: {
            stateKey: StudentDashboardStateKey.not_joining_program_cannot_reapply,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.not_joining_program_cannot_reapply,
                sidebarBoxes.pre_approved_for_cohort,
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.not_joining_program_cannot_reapply,
                sidebarBoxes.pre_approved_for_cohort,
                sidebarBoxes.follow_us,
            ],
            showDesktopProgramBox: true,
            showMobileProgramBox: true,
        },
        [StudentDashboardStateKey.not_joining_program_can_reapply]: {
            stateKey: StudentDashboardStateKey.not_joining_program_can_reapply,
            mobileSidebarBoxes: async () =>
                preApprovalBundleActivated
                    ? [
                          sidebarBoxes.offline,
                          sidebarBoxes.not_joining_program_can_reapply,
                          sidebarBoxes.pre_approved_for_cohort,
                          await admissionsInfoOrOpenCourses($injector),
                      ]
                    : [
                          sidebarBoxes.offline,
                          sidebarBoxes.not_joining_program_can_reapply,
                          await admissionsInfoOrOpenCourses($injector),
                          selectItemAtRandom([findFriendsBox, sidebarBoxes.view_upcoming_events]),
                      ],
            desktopSidebarBoxes: async () =>
                preApprovalBundleActivated
                    ? [
                          sidebarBoxes.offline,

                          sidebarBoxes.not_joining_program_can_reapply,
                          sidebarBoxes.pre_approved_for_cohort,
                          await admissionsInfoOrOpenCourses($injector),
                          findFriendsBox,
                          sidebarBoxes.our_degree_programs,
                          sidebarBoxes.follow_us,
                      ]
                    : [
                          sidebarBoxes.offline,

                          sidebarBoxes.not_joining_program_can_reapply,
                          await admissionsInfoOrOpenCourses($injector),
                          findFriendsBox,
                          sidebarBoxes.our_degree_programs,
                          knownCompany ? sidebarBoxes.company_tuition_funding : sidebarBoxes.our_tuition_model,
                          sidebarBoxes.follow_us,
                      ],
            modals: [{ route: '/settings/application', onlyShowOnce: true, modal: modals.welcomeToDashboard }],
            mobileTopMessageLocaleKey: topMessageLocaleKey('_mobile'),
            desktopTopMessageLocaleKey: topMessageLocaleKey('_desktop'),
            showDesktopProgramBox: true,
            showMobileProgramBox: true,
        },
        [StudentDashboardStateKey.unregistered_pre_approval]: {
            stateKey: StudentDashboardStateKey.unregistered_pre_approval,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.pre_approved_for_cohort,
                sidebarBoxes.admissions_info,
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.pre_approved_for_cohort,
                sidebarBoxes.admissions_info,
                employerFundingBox,
                findFriendsBox,
                sidebarBoxes.follow_us,
            ],
        },
        [StudentDashboardStateKey.declined_pre_approval]: {
            stateKey: StudentDashboardStateKey.declined_pre_approval,
            mobileSidebarBoxes: async () => [sidebarBoxes.offline, sidebarBoxes.pre_approved_for_cohort],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.pre_approved_for_cohort,
                employerFundingBox,
                findFriendsBox,
                sidebarBoxes.follow_us,
            ],
        },
        [StudentDashboardStateKey.graduated_pre_approval]: {
            stateKey: StudentDashboardStateKey.graduated_pre_approval,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                {
                    primary: sidebarBoxes.graduated,
                    key: sidebarBoxes.graduated.key,
                    others: [],
                },
                sidebarBoxes.pre_approved_for_cohort,
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.graduated,
                sidebarBoxes.pre_approved_for_cohort,
            ],
            showDesktopProgramBox: false,
        },
        [StudentDashboardStateKey.registered_not_current]: {
            stateKey: StudentDashboardStateKey.registered_not_current,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                // Either the enrollment or id verification box will be shown, but not both.
                // Identity verification is shown when it's the only remaining enrollment item.
                sidebarBoxes.enrollment,
                sidebarBoxes.identity_verification,
                sidebarBoxes.accepted,
                {
                    primary: sidebarBoxes.schedule_status,
                    key: sidebarBoxes.schedule_status.key,
                    others: [sidebarBoxes.this_week_in_slack, sidebarBoxes.upcoming_events],
                },
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.enrollment,
                sidebarBoxes.accepted,
                sidebarBoxes.identity_verification,
                sidebarBoxes.this_week_in_slack,
                sidebarBoxes.recent_courses,
            ],
            showDesktopProgramBox: true,
            showMobileProgramBox: false,
        },
        [StudentDashboardStateKey.registered_current]: {
            stateKey: StudentDashboardStateKey.registered_current,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.enrollment,
                sidebarBoxes.identity_verification,
                {
                    primary: sidebarBoxes.schedule_status,
                    key: sidebarBoxes.schedule_status.key,
                    others: [
                        sidebarBoxes.late_project_submission,
                        sidebarBoxes.schedule,
                        sidebarBoxes.this_week_in_slack,
                        sidebarBoxes.upcoming_events,
                    ],
                },
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.enrollment,
                sidebarBoxes.identity_verification,
                sidebarBoxes.schedule_status,
                sidebarBoxes.late_project_submission,
                sidebarBoxes.schedule,
                sidebarBoxes.this_week_in_slack,
                sidebarBoxes.recent_courses,
            ],
            showDesktopProgramBox: false,
            showMobileProgramBox: false,
        },
        [StudentDashboardStateKey.pre_graduation]: {
            stateKey: StudentDashboardStateKey.pre_graduation,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                // It's rare, but someone could have enrollment requirements still left
                // even after the cohort end date, so we include the enrollment and identifity_verification
                // boxes here, even though they'll almost always be hidden.
                sidebarBoxes.enrollment,
                sidebarBoxes.identity_verification,

                {
                    primary: sidebarBoxes.schedule_status,
                    key: sidebarBoxes.schedule_status.key,
                    others: [
                        sidebarBoxes.diploma_check,
                        sidebarBoxes.diploma_frames,
                        sidebarBoxes.transcripts,
                        sidebarBoxes.upcoming_events,
                    ],
                },
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                // See comment above sidebarBoxes.enrollment in pre_graduation->mobileSidebarBoxes
                sidebarBoxes.enrollment,
                sidebarBoxes.identity_verification,
                sidebarBoxes.schedule_status,
                sidebarBoxes.diploma_check,
                sidebarBoxes.diploma_frames,
                sidebarBoxes.transcripts,
                sidebarBoxes.recent_courses,
            ],
        },
        // FIXME: We should combine this with the `pre_graduation` state key in order to DRY things up.
        // As it is now, for example, if we enabled transcripts for the exec-ed programs,
        // the transcript sidebar would not show up, because it's not included here.
        [StudentDashboardStateKey.pre_graduation_digital_cert]: {
            stateKey: StudentDashboardStateKey.pre_graduation_digital_cert,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                // See comment above sidebarBoxes.enrollment in pre_graduation->mobileSidebarBoxes
                sidebarBoxes.enrollment,
                sidebarBoxes.identity_verification,
                {
                    primary: sidebarBoxes.schedule_status,
                    key: sidebarBoxes.schedule_status.key,
                    others: [sidebarBoxes.digital_certificate_check],
                },
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                // See comment above sidebarBoxes.enrollment in pre_graduation->mobileSidebarBoxes
                sidebarBoxes.enrollment,
                sidebarBoxes.identity_verification,
                sidebarBoxes.schedule_status,
                sidebarBoxes.digital_certificate_check,
            ],
        },
        [StudentDashboardStateKey.registered_no_application]: {
            stateKey: StudentDashboardStateKey.registered_no_application,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                {
                    primary: sidebarBoxes.schedule_status,
                    key: sidebarBoxes.schedule_status.key,
                    others: [sidebarBoxes.schedule],
                },
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.schedule_status,
                sidebarBoxes.schedule,
                sidebarBoxes.recent_courses,
            ],
            showDesktopProgramBox: false,
            showMobileProgramBox: false,
        },
        [StudentDashboardStateKey.graduated]: {
            stateKey: StudentDashboardStateKey.graduated,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                {
                    primary: sidebarBoxes.graduated,
                    key: sidebarBoxes.graduated.key,
                    others: [sidebarBoxes.upcoming_events, sidebarBoxes.transcripts],
                },
                sidebarBoxes.pre_approved_for_cohort,
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.graduated,
                sidebarBoxes.pre_approved_for_cohort,
                sidebarBoxes.transcripts,
                sidebarBoxes.recent_courses,
            ],
            // We currently only show the program box for graduated users
            // when they're in the biz-cert program. For degree programs that support having
            // a schedule, the `schedule_status` box shows all of the relevant
            // information for a graduated user.
            showDesktopProgramBox: !relevantCohort.supportsSchedule,
        },
        [StudentDashboardStateKey.will_not_graduate]: {
            stateKey: StudentDashboardStateKey.will_not_graduate,
            mobileSidebarBoxes: async () => [
                sidebarBoxes.offline,
                {
                    primary: sidebarBoxes.schedule_status,
                    key: sidebarBoxes.schedule_status.key,
                    others: [sidebarBoxes.upcoming_events],
                },
                sidebarBoxes.pre_approved_for_cohort,
            ],
            desktopSidebarBoxes: async () => [
                sidebarBoxes.offline,
                sidebarBoxes.schedule_status,
                sidebarBoxes.pre_approved_for_cohort,
                sidebarBoxes.recent_courses,
            ],
            showDesktopProgramBox: curriculumStatus !== CurriculumStatus.Failed,
            showMobileProgramBox: curriculumStatus !== CurriculumStatus.Failed,
        },
    };

    return updateDashboardConfigForAdmins(role, configs[stateKey], sidebarBoxes);
};

const memoizedBuildStudentDashboardConfig = memoizeOne(buildStudentDashboardConfig, isEqual);
const memoizedBuildAdmittedStudentInfo = memoizeOne((userBrandConfig: BrandConfig, config: FrontRoyalConfig) =>
    userBrandConfig.admittedStudentInfo(config),
);

const memoizedRelevantCohort = memoizeOne(
    (relevantCohort: CohortIguanaObject) => relevantCohort,
    ([new_cohort], [old_cohort]) =>
        new_cohort.version_id === old_cohort.version_id &&
        new_cohort.current_period_index === old_cohort.current_period_index,
);

const memoizedActiveProgramInclusion = memoizeOne(
    (programInclusion: ProgramInclusion | null) => programInclusion,
    ([new_program_inclusion], [old_program_inclusion]) =>
        new_program_inclusion?.id === old_program_inclusion?.id &&
        new_program_inclusion?.updatedAt === old_program_inclusion?.updatedAt,
);

const memoizedFilteredExecEdEligibilityBundle = memoizeOne(
    (bundle: ExecEdEligibilityBundle | null, admissionOffers: AdmissionOffer[] | [], relevantCohorts: Cohort[]) => {
        if (!bundle) return null;
        return getFilteredExecEdEligibilityBundle(bundle, admissionOffers, relevantCohorts);
    },
);

const getProgramInclusion = (currentUser: CurrentUserIguanaObject) => {
    const programInclusion = baseGetProgramInclusion(currentUser);
    return memoizedActiveProgramInclusion(programInclusion);
};

function studentDashboardConfig($injector: ng.auto.IInjectorService) {
    const currentUser = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
    const stateKey = determineStudentDashboardStateKey(currentUser, $injector);

    if (!stateKey) {
        return null;
    }
    if (!currentUser?.relevantCohort) {
        throw new Error('Expecting to have a user with a relevant cohort when we have a stateKey');
    }

    const userBrandConfig = targetBrandConfig(currentUser);
    const config = $injector.get<ConfigFactory>('ConfigFactory').getSync();

    const relevantCohort = memoizedRelevantCohort(currentUser.relevantCohort!);

    return memoizedBuildStudentDashboardConfig({
        $injector,
        stateKey,
        topMessageLocaleKeyPrefix: relevantCohort.topMessageLocaleKeyPrefix,
        userBrandConfig,
        featuredWorkExperienceCompany:
            currentUser.career_profile?.featuredWorkExperience?.professional_organization || null,
        admissionsSupportEmail: relevantCohort.supportEmailAddress('admissions'),
        inOfflineMode: $injector.get<OfflineModeManager>('offlineModeManager').inOfflineMode,
        admittedStudentInfo: memoizedBuildAdmittedStudentInfo(userBrandConfig, config),
        role: currentUser.roleName(),
        timezone: currentUser.timezone,
        chinaRegionMode: config.chinaRegionMode(),
        locale: currentUser.pref_locale,
        relevantCohort,
        curriculumStatus: getCurriculumStatus(currentUser),
        activeProgramInclusion: getProgramInclusion(currentUser),
        userName: currentUser.name,
        mailingAddress: currentUser.mailingAddress,
        inStudentNetwork: !!getProgramInclusion(currentUser)?.studentNetworkActivated,
        preApprovalBundleActivated: getHasActivatedExecEdEligibilityBundle(currentUser),
        filteredEligibilityBundle: memoizedFilteredExecEdEligibilityBundle(
            currentUser.execEdEligibilityBundle,
            currentUser.admissionOffers,
            getRelevantCohorts(currentUser),
        ),
        showEnrollment: shouldShowEnrollment($injector),
        showIdentityVerification: shouldShowIdVerification($injector),
    });
}

export default studentDashboardConfig;

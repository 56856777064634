import { faFileLines } from '@fortawesome/pro-regular-svg-icons/faFileLines';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAngularContext } from 'AngularContext';
import { useBioSig } from 'BioSig/hooks/useBioSig';
import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import getEnrollmentSidebarTodoGroups from 'Lessons/angularModule/scripts/stream/getEnrollmentSidebarTodoStuff';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { type CurrentUserIguanaObject } from 'Users';
import parseHtml from 'html-react-parser';
import { type EnrollmentTodoGroups, type TodoItem } from '../StudentDashboard.types';
import { EnrollmentTodoItem } from './EnrollmentTodoItem';
import { enrollmentTodoGroupVisible, enrollmentTodoVisible, hasCompleteItem } from './helpers';

const PAST_DUE = 'pastDue';

export function EnrollmentBox() {
    return <EnrollmentBoxPropsWrapper />;
}

function EnrollmentBoxPropsWrapper() {
    const $injector = useAngularContext();
    const currentUser = useCurrentUserIguanaObject();
    const { isLoadingBioSig, enrollBioSig } = useBioSig();
    const enrollmentTodoGroups = getEnrollmentSidebarTodoGroups($injector, {
        isLoadingBioSig,
        enrollBioSig,
    }) as EnrollmentTodoGroups;
    const { xsOrSm } = useIsMobileMixin();
    const [_todos, todosByDate] = enrollmentTodoGroups;

    if (!currentUser) return null;

    return <EnrollmentBoxPresentation currentUser={currentUser} xsOrSm={xsOrSm} todosByDate={todosByDate} />;
}

export function EnrollmentBoxPresentation({
    currentUser,
    xsOrSm,
    todosByDate,
}: {
    currentUser: CurrentUserIguanaObject;
    xsOrSm: boolean;
    todosByDate: { [dueDate: string]: TodoItem[] };
}) {
    const { t } = useTranslation('back_royal');
    const [showCompletedTodos, setShowCompletedTodos] = useState(false);

    const completedItemsToggleButtonIsVisible = useMemo(
        () => hasCompleteItem(Object.values(todosByDate).flat()),
        [todosByDate],
    );

    return (
        <div className="tw-bg-transparent tw-px-5 tw-py-[30px] md:tw-bg-[linear-gradient(168deg,_#a52e3b_0%,_#600b14_96%)]">
            {!xsOrSm && (
                <>
                    <h2 className="tw-mx-auto tw-my-0 tw-text-center before:tw-hidden">
                        <span className="tw-mb-[5px] tw-block tw-text-xs tw-font-normal tw-uppercase tw-text-white">
                            {t('lessons.stream.student_dashboard_enrollment.complete_your')}
                        </span>
                        <span className="tw-block tw-text-[28px] tw-font-semibold tw-uppercase tw-text-white">
                            {t('lessons.stream.student_dashboard_enrollment.enrollment')}
                        </span>
                        <span className="tw-mx-auto tw-mb-0 tw-mt-[10px] tw-flex tw-h-[40px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-full tw-bg-yellow tw-text-lg tw-font-normal tw-text-red-dark">
                            <FontAwesomeIcon icon={faFileLines} />
                        </span>
                    </h2>
                    <hr className="tw-mx-auto tw-my-5 tw-h-0 tw-w-[52px] tw-border-b tw-border-solid tw-border-b-[#ffffff80] tw-bg-transparent" />
                </>
            )}
            {Object.keys(todosByDate).map((dueDate: string) => {
                const todoItems = todosByDate[dueDate].filter(item => enrollmentTodoVisible(item, showCompletedTodos));
                return (
                    <Fragment key={dueDate}>
                        {dueDate === PAST_DUE && todoItems.length > 0 && (
                            <span className="tw-flex tw-items-center tw-justify-start tw-text-md tw-leading-6 tw-text-white">
                                {t('lessons.stream.student_dashboard_enrollment.past_due')}
                                <FontAwesomeIcon icon={faExclamationTriangle} className="tw-mx-1" />
                            </span>
                        )}
                        {dueDate !== PAST_DUE && enrollmentTodoGroupVisible(todoItems, showCompletedTodos) && (
                            <span className="due-by tw-text-md tw-leading-[26px] tw-text-white">
                                {t('lessons.stream.student_dashboard_enrollment.due_by')} {dueDate}
                            </span>
                        )}
                        {todoItems.map(item => (
                            <EnrollmentTodoItem key={item.name} item={item} showCompletedTodos={showCompletedTodos} />
                        ))}
                    </Fragment>
                );
            })}
            {completedItemsToggleButtonIsVisible && (
                <button
                    type="button"
                    data-testid="show-completed-toggle"
                    onClick={() => setShowCompletedTodos(prev => !prev)}
                    className="tw-h-auto tw-border-none tw-bg-transparent tw-p-0 tw-ps-6 tw-text-xs tw-normal-case tw-leading-7 tw-text-[#ffffff80] tw-shadow-none"
                >
                    {t(
                        `lessons.stream.student_dashboard_enrollment.${
                            showCompletedTodos ? 'hide_completed_steps' : 'show_completed_steps'
                        }`,
                    )}
                </button>
            )}
            {currentUser.relevantCohort?.enrollmentFaqLink && (
                <p className="sub-sub-text tw-m-0 tw-mt-5 tw-text-center">
                    <span className="tw-block tw-text-sm tw-text-white">
                        {parseHtml(
                            t('lessons.stream.student_dashboard_enrollment.learn_enrollment_requirements', {
                                enrollmentFaqLink: currentUser!.relevantCohort!.enrollmentFaqLink,
                            }),
                        )}
                    </span>
                    <span className="tw-block tw-text-sm tw-text-white">
                        {parseHtml(
                            t('lessons.stream.student_dashboard_enrollment.have_questions', {
                                brandEmail: currentUser!.relevantCohort!.supportEmailAddress('registrar'),
                            }),
                        )}
                    </span>
                </p>
            )}
        </div>
    );
}

import angularModule from 'AMap/angularModule/scripts/amap_module';

import { aMapLoaderWrapper, logAMapLoadError } from 'AMap';

angularModule.directive('aMarker', [
    '$injector',
    $injector => ({
        require: '^^aMap',
        restrict: 'E',
        transclude: true,
        scope: {
            position: '<',
        },
        link: (scope, element, attrs, mapCtrl) => {
            const ConfigFactory = $injector.get('ConfigFactory');
            const ErrorLogService = $injector.get('ErrorLogService');
            const amapWebKey = ConfigFactory.getSync(true).amap_js_api_key;
            const amapSecurityJsCode = ConfigFactory.getSync(true).amap_js_api_code;
            const mapConfig = $injector.get('AMAP_CONFIG');

            aMapLoaderWrapper(
                {
                    key: amapWebKey,
                    version: mapConfig.version,
                    AMapUI: mapConfig.amap_ui,
                },
                amapSecurityJsCode,
            )
                .then(AMap => {
                    scope.marker = new AMap.Marker({
                        position: scope.position,
                    });

                    scope.$watch('position', (newValue, oldValue) => {
                        if (newValue !== oldValue) {
                            scope.marker.setPosition(newValue);
                        }
                    });

                    mapCtrl.map.add(scope.marker);
                })
                .catch(e => {
                    logAMapLoadError(ErrorLogService, e);
                });
        },
    }),
]);


        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.admissions_modals = window.Smartly.locales.modules.en.back_royal.admissions_modals || {};
window.Smartly.locales.modules.en.back_royal.admissions_modals.application_received_modal = window.Smartly.locales.modules.en.back_royal.admissions_modals.application_received_modal || {};
window.Smartly.locales.modules.en.back_royal.admissions_modals.application_received_modal = {...window.Smartly.locales.modules.en.back_royal.admissions_modals.application_received_modal, ...{
    "we_received_your_application": "We received your application!",
    "need_answers": "Need answers?<br/><a ng-click=\"onClickResources()\">Browse resources</a>",
    "need_guidance": "Need 1:1 guidance?<br/><a ng-click=\"scheduleAdvisorChat()\">Schedule an advisor chat</a>",
    "friends_or_colleagues": "Find friends or colleagues<br/><a ng-click=\"onClickAlumniNetwork()\">Search alumni network</a>",
    "begin_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">Begin open courses</a>",
    "continue_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">Continue open courses</a>",
    "review_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">Review open courses</a>",
    "complete_first_course": "Complete the <a ng-click=\"onClickDashboard()\">first course</a> to sample our interactive curriculum and signal motivation to our Admissions team.",
    "continue_courses_business_admin": "<a ng-click=\"onClickDashboard()\">Continue courses</a> to cement key business concepts and signal degree readiness to our Admissions team.",
    "continue_courses_business_analytics": "<a ng-click=\"onClickDashboard()\">Continue courses</a> to cement key business analytics concepts and signal degree readiness to our Admissions team.",
    "continue_courses_software_engineering": "<a ng-click=\"onClickDashboard()\">Continue courses</a> to cement key software engineering concepts and signal degree readiness to our Admissions team.",
    "foundations_completed": "You have completed {{foundationsPlaylistTitle}}. You can find additional open courses on the <a ng-click=\"onClickCoursesTab()\">Courses</a> tab."
}
}
    
import cacheAngularTemplate from 'cacheAngularTemplate';
import { type CohortIguanaObject } from 'Cohorts';
import { type TranslationHelperInstance } from 'Translation';
import AdmissionsGuidanceModule from 'AdmissionsGuidance/angularModule/scripts/admissions_guidance_module';
import { SidebarBoxKey, type SidebarBoxConfig } from 'AdmissionsGuidance/StudentDashboard.types';
import { type CurriculumStatus } from 'ProgramInclusion';
import { hideThisWeeksScheduleWatcher } from './scheduleHelpers';

type params = {
    translationHelper: TranslationHelperInstance;
    relevantCohort: CohortIguanaObject;
    curriculumStatus: CurriculumStatus | null;
};

const desktopContentTemplate = cacheAngularTemplate(
    AdmissionsGuidanceModule,
    'AdmissionsGuidance/thisWeekInSlackDesktop',
    `<ul name="exercises" class="slack-schedule">
        <li ng-repeat="exercise in exercises | orderBy: 'date'">
            {{exercise.date | amDateFormat:'dddd, MMMM D'}} {{'lessons.stream.student_dashboard_schedule.at' | translate}} {{exercise.date | amDateFormat:'h:mm A'}}
            <span
                translate-once="lessons.stream.student_dashboard_schedule.in_the_room"
                translate-values="{room: exercise.channel}"
            ></span>
        </li>
    </ul>`,
);

export const thisWeekInSlackConfig = ({
    translationHelper,
    relevantCohort,
    curriculumStatus,
}: params): SidebarBoxConfig => ({
    key: SidebarBoxKey.this_week_in_slack,
    heading: translationHelper.get('heading_slack'),
    scope: { curriculumStatus, relevantCohort, exercises: relevantCohort.currentPeriod?.exercises || [] },
    desktopContentTemplate,
    shouldHide: !relevantCohort.currentPeriod?.exercises?.length,
    watchers: {
        ...hideThisWeeksScheduleWatcher,
    },
});

export default thisWeekInSlackConfig;

import AMapLoader from '@amap/amap-jsapi-loader';

export function aMapLoaderWrapper(mapConfig, securityJsCode) {
    // Add proxy address for amap security check
    if (!window._AMapSecurityConfig) {
        let host = window.origin;

        if (window.CORDOVA) {
            host = window.ENDPOINT_ROOT;
        }

        window._AMapSecurityConfig = {
            serviceHost: `${host}/_AMapService`,
        };

        // https://lbs.amap.com/api/javascript-api/guide/abc/prepare Method 2
        if (securityJsCode) {
            window._AMapSecurityConfig = {
                securityJsCode,
            };
        }
    }

    return AMapLoader.load(mapConfig);
}

export function logAMapLoadError(ErrorLogService, error) {
    if (ErrorLogService && error) {
        let message = error;

        if (error instanceof Error) {
            message = error.message;
        }

        ErrorLogService.notify(`AMap load failed due to: ${message}`);
    }
}

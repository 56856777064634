import { type AnyObject } from '@Types';
import cacheAngularTemplate from 'cacheAngularTemplate';
import template from 'AdmissionsGuidance/angularModule/views/schedule_status_box.html';
import angularModule from 'AdmissionsGuidance/angularModule/scripts/admissions_guidance_module';
import { SidebarBoxKey, type SidebarBoxConfig } from 'AdmissionsGuidance/StudentDashboard.types';
import { type StreamIguanaObject } from 'Lessons';
import { formattedBrandName } from 'AppBranding';
import { CurriculumStatus, isPreGraduationStatus } from 'ProgramInclusion';
import { type TranslationHelperInstance } from 'Translation';
import { formattedUserFacingMonthDayLong, formattedUserFacingMonthDayYearLong } from 'DateHelpers';
import {
    showsCertificateCheckInWhenInFinishedState,
    showsDiplomaCheckInWhenInFinishedState,
    type ProgramType,
} from 'Program';
import { getPastDueCourses, streamsByLocalePackId } from './scheduleHelpers';
import { type ScheduleStatusConfigParams } from './ScheduleBoxes.types';

const desktopContentTemplate = cacheAngularTemplate(angularModule, 'AdmissionsGuidance/ScheduleStatus', template);

function getPreGraduationFinishedHeader(programType: ProgramType, translationHelper: TranslationHelperInstance) {
    const status = translationHelper.get('status');

    // We only want to show this header when we're showing the diploma check-in because that's what
    // "prepare for graduation"refers to. It means we want you to do the diploma check
    // See similar logic in preGraduationText
    if (showsDiplomaCheckInWhenInFinishedState(programType))
        return translationHelper.get('heading_prepare_for_graduation');

    // Same comment as right above the previous check
    if (showsCertificateCheckInWhenInFinishedState(programType))
        return translationHelper.get('heading_check_your_certificate');

    // afaik, this is not a real state. Anyone who can be finished should be caught
    // by one of the above cases. But, adding a backstop just in case.
    return `${status}: ${translationHelper.get('finished')}`;
}

export const scheduleStatusConfig = ({
    curriculumStatus,
    relevantCohort,
    navigationHelpers,
    userBrandConfig,
    activeProgramInclusion,
    translationHelper, // lessons.stream.student_dashboard_boxes
}: ScheduleStatusConfigParams): SidebarBoxConfig => {
    const mobileHeadingOverride = () => {
        if (!curriculumStatus) return undefined;

        const status = translationHelper.get('status');

        if (activeProgramInclusion?.academicProbationStatus !== null) {
            return `${status}: ${translationHelper.get('academic_probation')}`;
        }

        const notOnTrackText = `${status}: ${translationHelper.get(CurriculumStatus.NotOnTrack)}`;
        const almostThereText = `${status}: ${translationHelper.get(CurriculumStatus.AlmostThere)}`;

        // The `programTitle` property for Exec Ed program types can be really long and cause the Failed and Week1
        // headings to wrap to 3+ lines, which causes the text to overflow its container. We decided the best course of action
        // was to show only the `fullTitle` property for Exec Ed programs, which is shorter and doesn't cause the text to overflow,
        // even at our lowest supported viewport width (360px).
        const headings: Record<CurriculumStatus, string> = {
            [CurriculumStatus.Failed]: relevantCohort.isExecEd
                ? relevantCohort.fullTitle
                : translationHelper.get('heading_the_program_title', {
                      programTitle: relevantCohort.programTitle,
                  }),
            [CurriculumStatus.PreGraduationFinished]: getPreGraduationFinishedHeader(
                relevantCohort.programType,
                translationHelper,
            ),
            [CurriculumStatus.PreGraduationNotOnTrack]: notOnTrackText,
            [CurriculumStatus.PreGraduationAlmostThere]: almostThereText,
            [CurriculumStatus.Graduated]: translationHelper.get('heading_congrats_you_have_graduated'),
            [CurriculumStatus.Honors]: translationHelper.get('heading_congrats_you_have_graduated'),
            [CurriculumStatus.Week0]: translationHelper.get('heading_start_date', {
                startDate: formattedUserFacingMonthDayLong(relevantCohort.startDate, false),
            }),
            [CurriculumStatus.Week1]: relevantCohort.isExecEd
                ? relevantCohort.fullTitle
                : `${translationHelper.get('welcome_to_the')} ${relevantCohort.programTitle}`,
            [CurriculumStatus.NotOnTrack]: notOnTrackText,
            [CurriculumStatus.OnTrack]: `${status}: ${translationHelper.get(CurriculumStatus.OnTrack)}`,
            [CurriculumStatus.OnTrackFinished]: `${status}: ${translationHelper.get(CurriculumStatus.OnTrackFinished)}`,
            [CurriculumStatus.AlmostThere]: almostThereText,
            [CurriculumStatus.WithdrawnOrExpelled]: '', // We hide the schedule status box if the user is expelled/withdrawn. See `shouldHide` below
            [CurriculumStatus.MissingGraduationRequirements]: `${status}: ${translationHelper.get(
                CurriculumStatus.MissingGraduationRequirements,
            )}`,
        };

        return headings[curriculumStatus];
    };

    const hasAcademicProbationStatus = activeProgramInclusion?.academicProbationStatus !== null;
    const hasNotOnTrackStatus = [CurriculumStatus.NotOnTrack, CurriculumStatus.PreGraduationNotOnTrack].some(
        s => s === curriculumStatus,
    );
    const hasAlmostThereStatus = [CurriculumStatus.AlmostThere, CurriculumStatus.PreGraduationAlmostThere].some(
        s => s === curriculumStatus,
    );

    return {
        key: SidebarBoxKey.schedule_status,
        scope: {
            brandStyleClass: userBrandConfig.brandStyleClass,
            formattedBrandName,
            hasPreGraduationStatus: isPreGraduationStatus(curriculumStatus),
            hasNotOnTrackStatus,
            hasAlmostThereStatus,
            hasAcademicProbationStatus,
            status: curriculumStatus,
            relevantCohort,
            loadUrl: navigationHelpers.loadUrl,
            loadRoute: navigationHelpers.loadRoute,
            showAllPastDueCourses: false,
            pastDueCourses: [],
            pastDueProjects: activeProgramInclusion?.pastDueProjects ?? [],
            numRequiredSpecializationsRemaining: activeProgramInclusion?.numRequiredSpecializationsRemaining ?? 0,
            week0LocaleKey: relevantCohort.hasSlackRooms ? 'week_0_message' : 'week_0_message_no_slack',
            toggleShowAllPastDueCoursesWithScope: (scope: AnyObject) => {
                scope.showAllPastDueCourses = !scope.showAllPastDueCourses;
            },
            graduationDateLocale: (() => {
                if (relevantCohort.refersToGraduation) {
                    return translationHelper.get('graduation_show_date', {
                        graduationDate: formattedUserFacingMonthDayYearLong(relevantCohort.graduationDate, false),
                    });
                }

                if (relevantCohort.supportsCertificate) {
                    return translationHelper.get('certificate_show_date', {
                        graduationDate: formattedUserFacingMonthDayYearLong(relevantCohort.graduationDate, false),
                    });
                }

                // This is not a real state. `graduationDateLocale` only shows in the finished state, and anyone
                // who can be in that state would be caught by one of the above cases. But, adding a backstop just in case.
                return translationHelper.get('program_completion_show_date', {
                    graduationDate: formattedUserFacingMonthDayYearLong(relevantCohort.graduationDate, false),
                });
            })(),
            preGraduationText: (() => {
                // See comment above in headings -> CurriculumStatus.Finished to understand why
                // we're using these particular checks here
                if (showsDiplomaCheckInWhenInFinishedState(relevantCohort.programType)) {
                    return translationHelper.get('complete_diploma_check');
                }

                if (showsCertificateCheckInWhenInFinishedState(relevantCohort.programType)) {
                    return translationHelper.get('you_will_be_notified_when_cert_is_available');
                }

                // This is not a real state. Any cohort that can see this would be caught by one of the above cases.
                // But, if somehow we get here, it doesn't look terrible to just have
                // no text in that section.
                return null;
            })(),
        },
        watchers: {
            streams: (scope, streams) => {
                const localePackIdHash = streamsByLocalePackId((streams as StreamIguanaObject[]) || []);
                scope.localePackIdHash = localePackIdHash;
                scope.pastDueCourses = getPastDueCourses(localePackIdHash, relevantCohort);
            },
        },
        desktopContentTemplate,
        mobileContentTemplate: desktopContentTemplate,
        mobileExpandable: true,
        mobileHeadingOverride: mobileHeadingOverride(),
        mobileCtaButtonTextOverride: translationHelper.get('button_view_details'),
        fullHeight: true,
        shouldHide:
            !relevantCohort.supportsSchedule ||
            (curriculumStatus === CurriculumStatus.NotOnTrack && !activeProgramInclusion) ||
            curriculumStatus === CurriculumStatus.WithdrawnOrExpelled,
    };
};

export default scheduleStatusConfig;

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { useCurrentUser, useNavigationHelper } from 'FrontRoyalAngular';
import { getProgramApplication } from 'Users';
import { useSyncConfig } from 'FrontRoyalConfig';
import { targetBrandConfig } from 'AppBranding';

export function ScheduleInterviewBox({ preRecorded }: { preRecorded: boolean }) {
    return <ScheduleInterviewPropsWrapper preRecorded={preRecorded} />;
}

function ScheduleInterviewPropsWrapper({ preRecorded }: { preRecorded: boolean }) {
    const { xsOrSm } = useIsMobileMixin();
    const { loadUrl } = useNavigationHelper();
    const currentUser = useCurrentUser();
    const appConfig = useSyncConfig();
    const interviewLink = useMemo(() => getProgramApplication(currentUser!)?.invitedToInterviewUrl, [currentUser]);
    const brandConfig = targetBrandConfig(currentUser);

    const handleButtonClick = useCallback(() => {
        if (preRecorded) {
            loadUrl(appConfig.preRecordedInterviewUrls()[brandConfig.branding], '_blank');
            return;
        }
        loadUrl(interviewLink, '_blank');
    }, [preRecorded, loadUrl, appConfig, interviewLink, brandConfig.branding]);

    return (
        <ScheduleInterviewPresentation xsOrSm={xsOrSm} preRecorded={preRecorded} onButtonClick={handleButtonClick} />
    );
}

export function ScheduleInterviewPresentation({
    xsOrSm,
    onButtonClick,
    preRecorded,
}: {
    xsOrSm: boolean;
    onButtonClick: () => void;
    preRecorded: boolean;
}) {
    const { t } = useTranslation('back_royal');

    const buttonLocaleKey = preRecorded
        ? 'lessons.stream.student_dashboard_schedule_interview.conduct_interview'
        : 'lessons.stream.student_dashboard_schedule_interview.schedule_interview';

    const desktopHeaderIntroLocaleKey = preRecorded
        ? 'lessons.stream.student_dashboard_schedule_interview.conduct_your'
        : 'lessons.stream.student_dashboard_schedule_interview.schedule_your';

    if (xsOrSm) {
        // MOBILE view
        return (
            <div data-testid="content-body" className="tw-p-5">
                <p className="tw-mb-[30px] tw-text-sm tw-font-normal tw-text-white">
                    {t('lessons.stream.student_dashboard_schedule_interview.congratulations')}
                </p>
                <button
                    className="tw-mb-[10px] tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-[50px] tw-border tw-border-solid tw-border-white tw-bg-transparent tw-px-[30px] tw-py-[10px] tw-text-base tw-font-medium tw-text-white tw-shadow-none"
                    type="button"
                    onClick={onButtonClick}
                >
                    {t(buttonLocaleKey)}
                </button>
            </div>
        );
    }

    // DESKTOP view
    return (
        <div
            data-testid="schedule-interview-box"
            className="tw-bg-[linear-gradient(162deg,_#2266d0_0%,_#0c2c63_96%)] tw-px-5 tw-py-[30px]"
        >
            <h2 className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                <span className="tw-mb-[5px] tw-block tw-text-xs tw-font-normal tw-uppercase tw-text-white">
                    {t(desktopHeaderIntroLocaleKey)}
                </span>
                <span className="tw-mb-[10px] tw-block tw-text-[28px] tw-font-semibold tw-uppercase tw-text-white">
                    {t('lessons.stream.student_dashboard_schedule_interview.interview')}
                </span>
                <span className="tw-mb-5 tw-flex tw-h-[40px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-full tw-bg-[#3e89fd] tw-text-lg tw-font-normal tw-text-white">
                    <FontAwesomeIcon icon={faCalendar} />
                </span>
            </h2>
            <p className="tw-mb-5 tw-text-sm tw-font-normal tw-text-white">
                {t('lessons.stream.student_dashboard_schedule_interview.congratulations')}
            </p>
            <hr className="tw-mx-auto tw-mb-5 tw-w-[52px] tw-bg-white tw-opacity-50" />
            <div className="tw-flex tw-items-center tw-justify-center">
                <button
                    type="button"
                    onClick={onButtonClick}
                    className="tw-m-0 tw-flex tw-h-auto tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-[50px] tw-border tw-border-white tw-bg-transparent tw-px-[30px] tw-py-[10px] tw-text-base tw-font-medium tw-normal-case tw-leading-[1.33] tw-tracking-[0.25px] tw-text-white tw-shadow-none tw-transition-all hover:tw-bg-white hover:tw-text-blue-dark"
                >
                    {t(buttonLocaleKey)}
                </button>
            </div>
        </div>
    );
}

import { type OfflineModeManager } from 'OfflineMode';
import { type ModalRouteConfig } from './AdmissionsProcessModals.types';
import type AdmissionsProcessModalsViewModel from './AdmissionsProcessModalsViewModel';
import showAdmissionsProcessModal from './showAdmissionsProcessModal';

type Props = {
    inOfflineMode: OfflineModeManager['inOfflineMode'];
    path: string;
    viewModel: AdmissionsProcessModalsViewModel;
    modalRouteConfig: ModalRouteConfig;
    injector: ng.auto.IInjectorService;
};

const showModalIfNecessary = async ({
    path,
    injector,
    viewModel,
    inOfflineMode,
    modalRouteConfig: { modal, onlyShowOnce, route },
}: Props) => {
    const hasShownOnRouteAlready = !!viewModel.visitedPaths[modal.key]?.[path];
    const shouldShowOnCurrentRoute = route === path;

    if (!shouldShowOnCurrentRoute) return;

    if (onlyShowOnce && hasShownOnRouteAlready) return;

    // See comment near where preventModalFromShowing is set in loadRouteAndHideAlerts
    if (viewModel.preventModalFromShowing) return;

    if (inOfflineMode) return;

    await showAdmissionsProcessModal(injector, modal);

    // Mark this modal as having shown on this route
    viewModel.visitedPaths = {
        ...viewModel.visitedPaths,
        [modal.key]: {
            ...viewModel.visitedPaths[modal.key],
            [path]: true,
        },
    };
};

export default showModalIfNecessary;

import { type ModalRouteConfig } from 'AdmissionsProcessModals/AdmissionsProcessModals.types';
import { type AnyObject } from '@Types';

export enum StudentDashboardStateKey {
    applied = 'applied',
    applied_admitted = 'applied_admitted',
    applied_conducted_interview = 'applied_conducted_interview',
    applied_invited_to_interview = 'applied_invited_to_interview',
    applied_invited_to_pre_recorded_interview = 'applied_invited_to_pre_recorded_interview',
    applied_scheduled_interview = 'applied_scheduled_interview',
    applied_will_not_be_invited_to_interview = 'applied_will_not_be_invited_to_interview',
    graduated = 'graduated',
    registered_not_current = 'registered_not_current',
    registered_current = 'registered_current',
    registered_no_application = 'registered_no_application',
    not_joining_program_can_reapply = 'not_joining_program_can_reapply',
    not_joining_program_cannot_reapply = 'not_joining_program_cannot_reapply',
    signup = 'signup',
    will_not_graduate = 'will_not_graduate',
    pre_graduation = 'pre_graduation',
    unregistered_pre_approval = 'unregistered_pre_approval',
    declined_pre_approval = 'declined_pre_approval',
    pre_graduation_digital_cert = 'pre_graduation_digital_cert',
    graduated_pre_approval = 'graduated_pre_approval',
}

export enum SidebarBoxKey {
    accepted = 'accepted',
    admissions_info = 'admissions_info',
    admissions_timeline = 'admissions_timeline',
    admitted_students_zoom = 'admitted_students_zoom',
    company_tuition_funding = 'company_tuition_funding',
    complete_application = 'complete_application',
    employer_funding = 'employer_funding',
    enrollment = 'enrollment',
    find_company_students = 'find_company_students',
    find_friends = 'find_friends',
    follow_us = 'follow_us',
    identity_verification = 'identity_verification',
    graduated = 'graduated',
    offline = 'offline',
    open_courses = 'open_courses',
    our_degree_programs = 'our_degree_programs',
    our_tuition_model = 'our_tuition_model',
    register_for_program = 'register_for_program',
    not_joining_program_can_reapply = 'not_joining_program_can_reapply',
    not_joining_program_cannot_reapply = 'not_joining_program_cannot_reapply',
    schedule_your_interview = 'schedule_your_interview',
    pre_recorded_interview = 'pre_recorded_interview',
    upload_documents = 'upload_documents',
    view_upcoming_events = 'view_upcoming_events',
    upcoming_events = 'upcoming_events',
    this_week_in_slack = 'this_week_in_slack',
    schedule = 'schedule',
    recent_courses = 'recent_courses',
    schedule_status = 'schedule_status',
    diploma_check = 'diploma_check',
    digital_certificate_check = 'digital_certificate_check',
    diploma_frames = 'diploma_frames',
    transcripts = 'transcripts',
    pre_approved_for_cohort = 'pre_approved_for_cohort',
    late_project_submission = 'late_project_submission',
}

export interface SidebarBoxButton {
    // the initial scope will be passed into the onClick
    onClick: (scope?: AnyObject) => void;
    text: string;
    classes?: string[];
    id: string;
    hideOnDesktop?: boolean;
}

export interface SidebarBoxConfig {
    key: SidebarBoxKey;
    classes?: string[];
    mobileContainerClasses?: string[];
    scope?: AnyObject;
    // Note that the callback shape here is slightly different than the typical cb for $scope.watch
    // this gives us scope in the first param which is typically the most useful
    watchers?: { [keyToWatch: string]: (scope: AnyObject, newValue?: unknown, oldValue?: unknown) => void };

    heading?: string;
    mobileHeadingOverride?: string;

    buttons?: SidebarBoxButton[];
    mobileCtaButtonTextOverride?: string;
    hideMobileCta?: boolean;

    desktopContentHtml?: string;
    desktopContentTemplate?: string;
    mobileContentHtml?: string;
    mobileContentTemplate?: string;
    mobileExpandable?: boolean;

    shouldHide?: boolean;
    fullHeight?: boolean;
}

// We can allow multiple side bar boxes to be rendered in one mobile ui box. The primary is responsible for setting up the
// header, buttons, etc. The others just get rendered under the primary once the box is opened.
// See the template for scheduleStatusConfig.ts for an example of how this could be used
type CombinedMobileSidebarBox = { primary: SidebarBoxConfig; others: SidebarBoxConfig[]; key: SidebarBoxKey };

export type MobileSidebarBox = CombinedMobileSidebarBox | SidebarBoxConfig;
export type MobileSidebarBoxes = MobileSidebarBox[];

export interface StudentDashboardConfig {
    stateKey: StudentDashboardStateKey;
    mobileTopMessageLocaleKey?: string;
    desktopTopMessageLocaleKey?: string;
    mobileSidebarBoxes: () => Promise<MobileSidebarBoxes>;
    desktopSidebarBoxes: () => Promise<SidebarBoxConfig[]>;
    modals?: ModalRouteConfig[];
    mobileContentBoxes?: SidebarBoxConfig[];
    showDesktopProgramBox?: boolean; // FIXME: remove once we convert student_dashboard_program_box to use student_dashboard_sidebar_box
    showMobileProgramBox?: boolean; // FIXME: remove once we convert student_dashboard_program_box to use student_dashboard_sidebar_box
}

export type AdmittedStudentsZoomStatus = 'saveTheDate' | 'watchRecording' | 'hidden';

export type TodoItem = {
    name: string;
    localeKey: string;
    isVisible: () => boolean;
    hasBeenCompleted: () => boolean;
    inReview?: () => boolean;
    irrelevant?: () => boolean;
    disabled: () => boolean;
    isLoading?: () => boolean;
    subTodosConfig?: SubTodosConfig;
    onClick: () => void;
    dueBy?: string;
};

export type SubTodosConfig = {
    isVisible: () => boolean;
    inReview: (subTodo: AnyObject) => boolean;
    hasBeenCompleted: (subTodo: AnyObject) => boolean;
    getSubTodos: () => AnyObject[];
    getSubTodoText: (subTodo: AnyObject) => string;
};

export type EnrollmentTodoGroups = [
    TodoItem[],
    { [dueDate: string]: TodoItem[] },
    { isLoadingBioSig: boolean; enrollBioSig: () => void; verifyBioSig: () => void },
];

/*

    In the Welcome to Dashboard modal, there is a button labeled "Admissions question?"

    When a user clicks this button, we do one of two things:

    1. If the user is on the student dashboard on mobile, then the admissions info box will
        be available in the mobile accordion at the bottom of the screen. We want to expand
        the accordion to show the admissions info box.
    2. Otherwise, we want to launch launch the helpscout beacon chat.

    To fulfill this requirement, when a user clicks on the button in the modal, we need to determine
    the state of the student dashboard.

    This file allows us to accomplish that while preserving the separation of concerns.

    In order to do so, this file exports two functions:

    1. listenForAdmissionsInfoMobileBoxTrigger is called from the directive that has the sidebar box. It
        sets up a listener on $rootScope that listens for someone to click on the "Admissions Question" button
        in the modal. When it observes the event, if the mobile box is visible, then it expands
        it makes a note on the event.
    1. triggerAdmissionsInfoMobileBox is called from the modal. It broadcasts the event that
        the sidebar box directive is listening for. Then, it inspects the event to find out
        if the accordion was expanded. If it was, then it does nothing. Otherwise, it launches the helpscout chat box.
*/

import { type HelpScoutBeacon } from 'HelpScoutBeacon';

type Props = {
    scope: angular.IScope;
    showMobileBoxKey: string;
    expandMobileBox: () => void;
};

const TRIGGER_EVENT = 'triggerAdmissionsInfoBoxIfOnMobile';
const MOBILE_BOX_EXPANDED = 'mobileBoxExpanded';
type TriggerEvent = angular.IAngularEvent & {
    [MOBILE_BOX_EXPANDED]?: boolean;
};

export function listenForAdmissionsInfoMobileBoxTrigger({ scope, showMobileBoxKey, expandMobileBox }: Props) {
    let cancelListener = () => {
        // Before we've started listening, the cancel function is a no-op
    };
    scope.$watch(showMobileBoxKey, mobileBoxVisible => {
        cancelListener();
        if (mobileBoxVisible) {
            cancelListener = scope.$on(TRIGGER_EVENT, (event: TriggerEvent) => {
                event[MOBILE_BOX_EXPANDED] = true;
                expandMobileBox();
            });
        }
    });

    scope.$on('$destroy', cancelListener);
}

export function triggerAdmissionsInfoMobileBox(injector: angular.auto.IInjectorService): void {
    const $rootScope = injector.get('$rootScope');
    const event: TriggerEvent = $rootScope.$broadcast(TRIGGER_EVENT);

    // If there was a mobile box on the screen, and it was expanded, then we don't need to do anything
    // else. Otherwise, we need to launch the chat.
    if (!event[MOBILE_BOX_EXPANDED]) {
        injector.get<HelpScoutBeacon>('helpScoutBeacon').openAskScreen();
    }
}

import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { type OfflineModeManager } from 'OfflineMode';
import { shouldShowEnrollment } from './shouldShowEnrollment';

export const shouldShowIdVerification = ($injector: ng.auto.IInjectorService) => {
    const currentUser = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
    const inOfflineMode = $injector.get<OfflineModeManager>('offlineModeManager').inOfflineMode;
    return !!(
        currentUser?.unverifiedForCurrentIdVerificationPeriod &&
        !shouldShowEnrollment($injector) &&
        !inOfflineMode
    );
};

import { type AnyObject } from '@Types';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { addDays } from 'DateHelpers';
import { type DialogModal } from 'DialogModal';
import { type EventLogger } from 'EventLogger';
import formattedDateForEvent from 'StudentNetwork/formattedDateForEvent';
import { type StudentNetworkEvent, type StudentNetworkInstance } from 'StudentNetwork/StudentNetwork.types';
import { type TranslationHelperInstance } from 'Translation';
import upcomingEventsBoxDesktopTemplate from 'AdmissionsGuidance/angularModule/views/upcoming_events_box_desktop.html';
import AdmissionsGuidanceModule from 'AdmissionsGuidance/angularModule/scripts/admissions_guidance_module';
import { type SidebarBoxConfig, SidebarBoxKey } from 'AdmissionsGuidance/StudentDashboard.types';
import { type CurriculumStatus, type ProgramInclusion } from 'ProgramInclusion';

type EventsMap = {
    [title: string]: StudentNetworkEvent[];
};

type params = {
    $injector: ng.auto.IInjectorService;
    translationHelper: TranslationHelperInstance;
    EventLogger: EventLogger;
    loadRoute: (path: string) => void;
    DialogModal: DialogModal;
    chinaRegionMode: boolean;
    timezone: string | null;
    supportsNetworkAccess: boolean;
    inOfflineMode: boolean;
    activeProgramInclusion: ProgramInclusion | null;
    curriculumStatus: CurriculumStatus | null;
};

const desktopContentTemplate = cacheAngularTemplate(
    AdmissionsGuidanceModule,
    'AdmissionsGuidance/upcomingEventsBoxDesktop',
    upcomingEventsBoxDesktopTemplate,
);

export const upcomingEventsConfig = ({
    $injector,
    translationHelper,
    EventLogger,
    loadRoute,
    DialogModal,
    chinaRegionMode,
    timezone,
    supportsNetworkAccess,
    inOfflineMode,
    activeProgramInclusion,
    curriculumStatus,
}: params): SidebarBoxConfig => {
    const StudentNetworkEvent = $injector.get<StudentNetworkInstance>('StudentNetworkEvent');
    const hideEventsForExpelledUsers = !activeProgramInclusion && curriculumStatus === 'not_on_track';

    const logAndNavigateToEvents = (eventId?: string) => {
        EventLogger.log('student-dashboard:navigate-to-student-network', { student_network_event_id: eventId });
        loadRoute(`/student-network?${eventId ? `event-id=${eventId}` : 'show-events'}`);
    };

    return {
        key: SidebarBoxKey.upcoming_events,
        heading: translationHelper.get('heading_upcoming_events'),
        desktopContentTemplate,
        scope: {
            StudentNetworkEvent,
            supportsNetworkAccess,
            inOfflineMode,
            eventsByTitle: {},
            orderedEventTitles: [],
            formattedDateForEvent: (event: StudentNetworkEvent) =>
                formattedDateForEvent(event, chinaRegionMode ? 'Asia/Shanghai' : timezone),
            hasMoreEvents: false,
            numEventsToShow: 3,
            onEventClick: (event: StudentNetworkEvent) => logAndNavigateToEvents(event.id),
            openEventGroupPopovers: {},
            onPopoverCloseClickWithScope: (scope: AnyObject) => {
                scope.openEventGroupPopovers = {};
            },
        },
        watchers: {
            StudentNetworkEvent: scope => {
                StudentNetworkEvent.index({ sort: 'start_time' })
                    .catch(err => {
                        throw err;
                    })
                    .then(({ result }) => {
                        scope.eventsByTitle = result.reduce<EventsMap>((prev, curr) => {
                            if (!curr.recommended || new Date(curr.start_time * 1000) > addDays(14)) return prev;

                            prev[curr.title] = [...(prev[curr.title] || []), curr].sort(
                                (a, b) => a.start_time - b.start_time,
                            );

                            return prev;
                        }, {});
                    });
            },
            eventsByTitle: (scope, eventsByTitle) => {
                scope.orderedEventTitles = Object.entries(eventsByTitle as EventsMap)
                    .sort(([_a, aValue], [_b, bValue]) => aValue[0].start_time - bValue[0].start_time)
                    .map(([key]) => key);
                scope.hasMoreEvents = (scope.orderedEventTitles as []).length > (scope.numEventsToShow as number);
            },
            hasMoreEvents: (scope, hasMoreEvents) => {
                (scope.sidebarBoxConfig as SidebarBoxConfig).buttons![0].text = translationHelper.get(
                    'button_upcoming_events',
                    {
                        hasMoreEvents,
                    },
                );
            },
            'supportsNetworkAccess,inOfflineMode': scope => {
                scope.hide = !scope.supportsNetworkAccess || !!scope.inOfflineMode || hideEventsForExpelledUsers;
            },
            xsOrSm: (scope: AnyObject, xsOrSm) => {
                // if the dialog modal is open and we are no longer in xsOrSm view, we need to close it
                if (!xsOrSm && document.querySelector('student-dashboard-schedule-event-group')) {
                    DialogModal.removeAlerts();
                }

                scope.handleEventGroupClick = (eventTitle: string) => {
                    if (!xsOrSm) {
                        scope.openEventGroupPopovers = { [eventTitle]: true };
                    } else {
                        const events = (scope.eventsByTitle as EventsMap)[eventTitle];
                        DialogModal.alert({
                            content: `<student-dashboard-schedule-event-group events="events"></student-dashboard-schedule-event-group>`,
                            classes: ['event-group-mobile-alert'],
                            scope: {
                                events,
                            },
                        });
                    }
                };
            },
        },
        buttons: [
            {
                id: 'button_upcoming_events',
                text: translationHelper.get('button_upcoming_events', { hasMoreEvents: false }),
                onClick: scope => {
                    if (scope!.hasMoreEvents) {
                        scope!.numEventsToShow = (scope!.orderedEventTitles as string[]).length;
                        scope!.hasMoreEvents = false;
                    } else {
                        logAndNavigateToEvents();
                    }
                },
            },
        ],
    };
};

export default upcomingEventsConfig;

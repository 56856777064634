import { useEffect, useState, useCallback } from 'react';
import memoizeOne from 'memoize-one';
import { isEqual } from 'lodash/fp';
import { useAngularContext } from 'AngularContext';
import attachAMapAutoCompleteToInput from './attachAMapAutoCompleteToInput';

const memoizeOptions = memoizeOne(options => options, isEqual);

export default function useAMapAutoComplete({ autoCompleteOptions, onPlaceChanged }) {
    const $injector = useAngularContext();

    const [input, setInput] = useState();

    const inputRef = useCallback(node => {
        let _input;

        if (!node) {
            return;
        }

        // Find the input element that we want to attach google places to
        if (node.nodeName.toLowerCase() === 'input') {
            _input = node;
        } else {
            _input = node.querySelector('input');
        }
        if (!_input) {
            throw new Error('No input element found in referenced DOM element.');
        }

        setInput(_input);
    }, []);

    const [cancelListener, setCancelListener] = useState();
    const _autoCompleteOptions = memoizeOptions(autoCompleteOptions);
    const ConfigFactory = $injector.get('ConfigFactory');
    const chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();

    useEffect(() => {
        if (input && chinaRegionMode) {
            const _cancelListener = attachAMapAutoCompleteToInput({
                $injector,
                input,
                options: {
                    autoCompleteOptions: _autoCompleteOptions,
                },
                onAMapAutoCompleteInitialized: null,
                onPlaceChanged,
            });

            // We have to wrap _addListener in a function.  Otherwise,
            // react will call _addListener() rather than assigning it
            setCancelListener(() => _cancelListener);
        } else {
            setCancelListener(null);
        }
    }, [$injector, input, _autoCompleteOptions, onPlaceChanged, chinaRegionMode]);

    useEffect(() => {
        if (cancelListener) {
            return () => cancelListener && cancelListener();
        }
        return () => {};
    }, [cancelListener]);

    return inputRef;
}
